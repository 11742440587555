/**
 * Created by mateimisarca on 24.05.2021
 */

import { createAction } from 'redux-actions';
import { MODAL_OPEN, MODAL_CLOSE } from './constants';

export const feedbackModalOpen = createAction(MODAL_OPEN);
export const feedbackModalClose = createAction(MODAL_CLOSE);

