/**
 * Created by mateimisarca on 21/09/2020
 */

import React, { Component } from 'react';
import { Form, Col, Row, Button, Card } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import makeSelectAuth from 'containers/AuthContainer/selectors';
import { makeSelectActivePage } from 'containers/App/selectors';
import { registerRequest } from 'containers/AuthContainer/actions';

import { renderInput } from 'utils/forms/input';
import { renderCheck } from 'utils/forms/check';
import getValidator from 'utils/validation';

import registerValidation from './validation';
import showNotification from 'containers/ToastContainer/actions';
import FormattedMessage from 'components/FormattedMessage/FormattedMessage';

const mapStateToProps = state => ({
    auth: makeSelectAuth(state),
    // user: makeSelectUser(state),
    lastPage: makeSelectActivePage(state),
});

const mapDispatchToProps = dispatch => ({
    addToast: toast => dispatch(showNotification(toast)),
    goTo: page => dispatch(push(page)),
});

const submit = (goTo, addToast, intl, values, dispatch) => {
    registerRequest({ ...values }, dispatch)
        .then(() => {
            addToast({
                title: (
                    <FormattedMessage { ...intl.messages.register.successTitle } />
                ),
                body: (
                    <FormattedMessage
                        { ...intl.messages.register.successBody }
                    />
                ),
                kind: 'success',
            });
            goTo('/auth/register/success');
        });
};

export default @compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'registerForm',
        asyncValidate: getValidator(registerValidation),
    }),
)
class Register extends Component {
    renderRegisterForm() {
        const {
            intl,
            intl: {
                formatMessage,
                messages,
            },
            handleSubmit,
            addToast,
            goTo,
        } = this.props;

        return (
            <Form onSubmit={ handleSubmit(submit.bind(null, goTo, addToast, intl)) }>
                <Field
                    name="firstName"
                    type="text"
                    component={ renderInput }
                    placeholder={ formatMessage(messages.register.firstName) }
                />
                <Field
                    name="lastName"
                    type="text"
                    component={ renderInput }
                    placeholder={ formatMessage(messages.register.lastName) }
                />
                <Field
                    name="email"
                    type="email"
                    component={ renderInput }
                    placeholder={ formatMessage(messages.register.email) }
                />
                <Field
                    name="password"
                    type="password"
                    component={ renderInput }
                    placeholder={ formatMessage(messages.register.password) }
                />
                <Field
                    name="passwordConfirmation"
                    type="password"
                    component={ renderInput }
                    placeholder={ formatMessage(messages.register.passwordConfirmation) }
                />
                <Field
                    name="tc"
                    component={ renderCheck }
                    label={ formatMessage(messages.register.tc) }
                />

                <Form.Group as={ Row }>
                    <Col sm={ { span: 9, offset: 3 } }>
                        <Button type="submit">Register</Button>
                    </Col>
                </Form.Group>
            </Form>
        );
    }

    render() {
        const {
            formatMessage,
            messages,
        } = this.props.intl;

        return (
            <main className="main">
                <Card>
                    <Card.Header as="h5">
                        { formatMessage(messages.register.title) }
                    </Card.Header>
                    <Card.Body>
                        { this.renderRegisterForm() }
                    </Card.Body>
                </Card>
            </main>
        );
    }
}
