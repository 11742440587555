/**
 * Created by mateimisarca on 17/09/2020
 */

import { all } from 'redux-saga/effects';

import { authSagas } from 'containers/AuthContainer/saga';
import { userSagas } from 'containers/UserContainer/saga';
import { appSagas } from 'containers/App/saga';
import { productsSagas } from 'containers/ProductsContainer/saga';
import { pageSagas } from 'containers/PageContainer/saga';

export default function* rootSagas() {
  yield all([
    ...appSagas,
    ...authSagas,
    ...userSagas,
    ...productsSagas,
    ...pageSagas,
  ]);
}
