/**
 * Created by mateimisarca on 21/09/2020
 */

import { shape, string } from 'prop-types';

const UserPropType = shape({
    name: string,
    email: string,
    photo: string,
});

export const UserSettings = shape({
    language: string,
    currency: string,
    currencyFormat: string,
    numberFormat: string,
    theme: string,
});

export default UserPropType;