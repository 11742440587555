/**
 * Created by mateimisarca on 21/09/2020
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import cx from 'classnames';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';

import { makeSelectUser } from './selectors';
import UserPropType from './types';

import './UserContainer.scss';

const mapStateToProps = state => ({
  user: makeSelectUser(state),
});

const mapDispatchToProps = dispatch => ({
  goTo: page => dispatch(push(page)),
});

export default @compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)
class UserContainer extends Component {
  static propTypes = {
    user: UserPropType.isRequired,
  };

  render() {
    const { user, defaultIcon, className, goTo } = this.props;

    if (!user || (isEqual(user.firstName, '') || isEqual(user.firstName, undefined))) return null;

    return (
      <div className={ cx('UserContainer', className) }>
        <div className="UserContainer-userName">
          <div onClick={ () => goTo('/profile') }><span>Hello</span> <b>{ ` ${ user.firstName } ${ user.lastName }` }</b></div>
        </div>
        { user.photoURL ? (
          <div className="UserContainer-userPhoto" style={ { backgroundImage: `url(${ user.photoURL })` } } />
        ) : defaultIcon }
      </div>
    );
  }
}
