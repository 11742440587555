/**
 * Created by mateimisarca on 17/09/2020
 */

import React, { Component, Fragment } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bool, object, shape } from 'prop-types';
import { Redirect } from 'react-router';
import { uniqueId, find } from 'lodash';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';

import { loginRequest } from 'containers/AuthContainer/actions';
import makeSelectAuth from 'containers/AuthContainer/selectors';
import { makeSelectActivePage } from 'containers/App/selectors';
import PagePropTypes from 'containers/PageContainer/types';
import { errorMessages } from 'containers/AuthContainer/messages';

import Notification from 'components/Notification/Notification';

import getValidator from 'utils/validation';
import { renderInput } from 'utils/forms/input';

import loginValidation from './validation';
import './Login.scss';

const mapStateToProps = state => ({
    auth: makeSelectAuth(state),
    // user: makeSelectUser(state),
    lastPage: makeSelectActivePage(state),
});

const mapDispatchToProps = dispatch => ({
    goTo: page => dispatch(push(page)),
});

const submit = (values, dispatch) => {
    loginRequest({ ...values }, dispatch);
};

export default @compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'loginForm',
        asyncValidate: getValidator(loginValidation),
    }),
)
class Login extends Component {
    static propTypes = {
        auth: shape({
            loggedIn: bool.isRequired,
            error: object,
        }),
        lastPage: PagePropTypes,
    };

    static defaultProps = {
        lastPage: {
            pathname: '/',
            search: '',
        },
    };

    renderLogin() {
        const {
            intl: {
                formatMessage,
                messages,
            },
        } = this.props;

        return (
            <Fragment>
                <Field
                    name="email"
                    type="email"
                    component={ renderInput }
                    placeholder={ formatMessage(messages.login.email) }
                />
                <Field
                    name="password"
                    type="password"
                    component={ renderInput }
                    placeholder={ formatMessage(messages.login.password) }
                />
            </Fragment>
        );
    }

    render() {
        const {
            handleSubmit,
            auth: { loggedIn, error },
            lastPage,
            intl: {
                formatMessage,
                messages,
            },
            goTo,
        } = this.props;

        if (loggedIn) {
            return <Redirect to={ lastPage?.pathname + lastPage?.search || '/' } />;
        }

        return (
            <Modal
                show
                onHide={ () => {
                    goTo('/')
                } }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="login"
            >
                <Form onSubmit={ handleSubmit(submit) }>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Login
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { this.renderLogin() }
                        <div className="login-registerButton">
                            { formatMessage(messages.login.createAccountMessage) }
                            <Button variant="link">
                                <Link to="/auth/register">
                                    { formatMessage(messages.login.createAccountButton) }
                                </Link>
                            </Button>
                        </div>
                        { error?.message && (
                            <div>
                                <Notification
                                    key={ uniqueId('_err') }
                                    kind="error"
                                    title={ formatMessage(messages.generic.error) }
                                    subtitle={ formatMessage(
                                        find(errorMessages, msg => msg.id === error?.message),
                                    ) }
                                    hideCloseButton
                                />
                            </div>
                        ) }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning">
                            Forgot Password
                        </Button>
                        <Button variant="primary" type="submit">
                            Login
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}
