/**
 * Created by mateimisarca on 26.04.2021
 */

import React, { Component } from 'react';
import cx from 'classnames';
import { bool } from 'prop-types';

import './Checkbox.scss';

export default class Checkbox extends Component {
  static propTypes = {
    disabled: bool,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    const { checked, onClick, labelText, indeterminate, disabled } = this.props;

    return (
      <div className={ cx('Checkbox', {
        'Checkbox--disabled': disabled,
      }) }>
        <label className="Checkbox-label">
          <div className="Checkbox-container">
            <input
              className="Checkbox-input"
              type="checkbox"
              checked={ checked || indeterminate }
              onChange={ onClick }
              disabled={ disabled }
            />
            <div className={ cx('Checkbox-styled', {
              'Checkbox-styled--checked': checked || indeterminate,
              'Checkbox-styled--disabled': disabled,
            }) }>
              <svg className="Checkbox-icon" viewBox="0 0 24 24">
                { checked ? <polyline points="20 6 9 17 4 12" /> :
                  <polyline points="18 12 6 12" /> }
              </svg>
            </div>
          </div>
          <span>{ labelText }</span>
        </label>
      </div>
    );
  }
}
