/**
 * Created by mateimisarca on 26.04.2021
 */

import React, { Fragment, PureComponent } from 'react';
import cx from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { map, kebabCase, cloneDeep, filter, includes, toUpper, join } from 'lodash';
import { bool, oneOf } from 'prop-types';

import RadioButton from 'components/RadioButton';
import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';

import './DropdownTree.scss';

export default class DropdownTree extends PureComponent {
  static propTypes = {
    mode: oneOf(['singleSelect', 'multiSelect']),
    showDropdown: oneOf(['always', 'default']),
    selectOnlyLeaves: bool
  };

  static defaultProps = {
    showDropdown: 'default',
    mode: 'multiSelect',
  };

  constructor() {
    super();

    this.state = {
      isDropdownOpen: false,
      find: '',
    };
    this.onOpenDropdown = this.onOpenDropdown.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  onOpenDropdown(value) {
    this.setState({
      isDropdownOpen: value || !this.state.isDropdownOpen,
    });
  }

  onIconClick(menuItem, evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const { onNodeToggle, data } = this.props;
    const newMenuItem = cloneDeep(menuItem);
    newMenuItem.expanded = !menuItem.expanded;

    onNodeToggle && onNodeToggle(newMenuItem);
  }

  onRadioClick(menuItem) {
    const { onChange, data } = this.props;
    const newMenuItem = cloneDeep(menuItem);
    newMenuItem.checked = !newMenuItem.checked;

    onChange && onChange(newMenuItem, data);
  }

  onSearch(evt) {
    this.setState({
      find: evt.target.value,
    });
  }

  flatten(arr) {
    return arr ? arr.reduce((result, item) => [
      ...result,
      { ...item },
      ...this.flatten(item.children),
    ], []) : [];
  }

  getFilteredData(data, find) {
    return filter(this.flatten(data), o => includes(toUpper(o.label), toUpper(find)));
  }

  static getLabels(data) {
    if (!data) return null;

    const labels = [];
    for (const i of data) {
      if (i.checked) {
        labels.push(i.label);
      } else if (i.partial) {
        const label = `${ i.label }: ${ DropdownTree.getLabels(i.children) }`;
        labels.push(label);
      }
    }
    return join(labels, ', ');
  }

  renderDropdownTreeHeader() {
    const { isDropdownOpen } = this.state;
    const { placeholderText, disabled, data, error } = this.props;

    return (
      <div className="DropdownTree-dropdown dropdown">
        <a className={ cx('DropdownTree-trigger form-control', {
          'DropdownTree-trigger--open': isDropdownOpen && !disabled,
          'DropdownTree-trigger--error': error
        }) }
           id="tribeAndSquad_trigger"
           role="button"
           onClick={ this.onOpenDropdown }
        >
          <div className="DropdownTree-text">{ DropdownTree.getLabels(data) || placeholderText }</div>
          <span>
            { isDropdownOpen ? (
              <Icon icon="fas fa-sort-up" iconTitle="Hide" />
            ) : (
              <Icon icon="fas fa-sort-down" iconTitle="Expand" />
            ) }
          </span>
        </a>
      </div>
    );
  }

  renderDropdownTreeItems(data, lvl = 0) {
    const { mode, selectOnlyLeaves } = this.props;

    return (
      <div>
        { lvl === 0 && (
          <div className="DropdownTree-menuItem DropdownTree-menuItem--search" style={ {
            paddingLeft: `${ 4 + (20 * lvl) }px`,
          } }>
            <input
              type="text"
              className="search DropdownTree-search form-control"
              disabled={ false }
              placeholder="search"
              onClick={ this.onOpenDropdown.bind(this, true) }
              value={ this.state.find }
              onChange={ this.onSearch }
            />
          </div>
        ) }
        { map(data, menuItem => (
          <Fragment key={ kebabCase(menuItem.id) }>
            <div className="DropdownTree-menuItem" style={ {
              paddingLeft: `${ 4 + (20 * lvl) }px`,
            } }>
              <div className="DropdownTree-menuItemIcon">
                {
                  menuItem.children ?
                    (menuItem.expanded ? (
                        <div onClick={ this.onIconClick.bind(this, menuItem) }>
                          <i className="far fa-minus-square" />
                        </div>
                      ) : (
                        <div onClick={ this.onIconClick.bind(this, menuItem) }>
                          <i className="far fa-plus-square" />
                        </div>
                      )
                    ) : <span>&nbsp;</span>
                }
              </div>
              { mode === 'singleSelect' ? (
                <RadioButton
                  id={ kebabCase(menuItem.path) }
                  className={ cx('DropdownTree-menuItemRadio', {
                    'DropdownTree-menuItemRadio--partial': menuItem.partial,
                    'DropdownTree-menuItemRadio--checked': menuItem.checked,
                  }) }
                  name={ menuItem.path }
                  value={ menuItem.label }
                  onClick={ this.onRadioClick.bind(this, menuItem) }
                  checked={ menuItem.checked }
                  disabled={ menuItem.disabled || (selectOnlyLeaves && !!menuItem.children) }
                  labelText={ menuItem.label }
                  partial={ menuItem.partial }
                />
              ) : (
                <Checkbox
                  id={ kebabCase(menuItem.path) }
                  className={ cx('DropdownTree-menuItemRadio', {
                    'DropdownTree-menuItemRadio--partial': menuItem.partial,
                    'DropdownTree-menuItemRadio--checked': menuItem.checked,
                  }) }
                  name={ menuItem.path }
                  labelText={ menuItem.label }
                  onClick={ this.onRadioClick.bind(this, menuItem) }
                  checked={ menuItem.checked }
                  indeterminate={ menuItem.partial }
                  disabled={menuItem.disabled || (selectOnlyLeaves && !!menuItem.children)}
                />
              ) }
              {/*<span className="DropdownTree-menuItemLabel">{menuItem.label}</span>*/ }
            </div>
            { menuItem.children && menuItem.expanded && this.renderDropdownTreeItems(menuItem.children, lvl + 1) }

          </Fragment>
        )) }
      </div>
    );
  }

  renderDropdownTreeBody() {
    const { data } = this.props;
    const { find } = this.state;
    const actualData = find && find !== '' && find.length > 2 ? this.getFilteredData(data, find) : data;

    return (
      <div className="DropdownTree-content">
        { this.renderDropdownTreeItems(actualData) }
      </div>
    );
  }

  render() {
    const {
      id,
      className,
      disabled,
      showDropdown,
    } = this.props;
    const { isDropdownOpen } = this.state;

    return (
      <OutsideClickHandler
        onOutsideClick={ () => this.setState({ isDropdownOpen: false }) }
      >
        <div
          id={ id }
          className={ cx('DropdownTree', className, 'DropdownTree--extension', {
            'DropdownTree--disabled': disabled,
          }) }
        >
          { this.renderDropdownTreeHeader() }
          { (isDropdownOpen || (showDropdown === 'always')) && !disabled && this.renderDropdownTreeBody() }
        </div>
      </OutsideClickHandler>
    );
  }
}

