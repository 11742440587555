/**
 * Created by mateimisarca on 21/09/2020
 */

import { createSelector } from 'reselect';

const getAuth = state => state.auth;

const makeSelectAuth = createSelector(
    getAuth,
    authState => authState,
);

export const makeSelectLoggedIn = createSelector(
    getAuth,
    authState => authState.loggedIn,
);

export default makeSelectAuth;
