/**
 * Created by mateimisarca on 17/09/2020
 */

import React, { PureComponent } from 'react';
import { element, shape } from 'prop-types';
import { IntlProvider, injectIntl } from 'react-intl';
import { values, join } from 'lodash';

// const { ...intl } = IntlProvider.propTypes;

export default @injectIntl
class LanguageProviderWrapper extends PureComponent {
    static propTypes = {
        children: element.isRequired,
        intl: shape(IntlProvider.propTypes),
    };

    render() {
        const { children, intl } = this.props;
        // const { intl } = this.context;
        const formatMessageRef = intl.formatMessage;
        intl.formatMessage = (...rest) => {
            if (!rest.length) {
                return console.warn(`[React Intl] No message provided`);
            }
            const [msg = {}] = rest;
            if (!msg.id) {
                const message = join(values(msg), '');
                if (typeof message === 'string' && message.length > 0) {
                    return formatMessageRef(
                        ...[
                            {
                                id: message,
                                defaultMessage: message,
                            },
                        ],
                    );
                }

                return console.warn(`[React Intl] Invalid message type. Please provide an object like { id, defaultMessage }`);
            }

            return formatMessageRef(...rest);
        };
        return React.Children.only(children);
    }
}

