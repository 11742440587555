/**
 * Created by mateimisarca on 21/09/2020
 */

import { createSelector } from 'reselect';
import updateObject from '../../utils/updateObject';

const getUser = state => state.user;

export const makeSelectUser = createSelector(
    getUser,
    userState => {
        let shortName = '';
        if (userState?.name) {
            shortName = userState.name;
        }

        return updateObject(userState, { shortName });
    },
);
