/**
 * Created by mateimisarca on 24.05.2021
 */

import { defineMessages } from 'react-intl';

export const fieldMessages = defineMessages({
  DATA_ISSUE: {
    id: 'app.Feedback.fields.dataIssues',
    defaultMessage: 'Data Issues',
  },
  NEW_FUNC: {
    id: 'app.Feedback.fields.functionality',
    defaultMessage: 'New Functionality',
  },
  UI_ISSUE: {
    id: 'app.Feedback.fields.uiIssues',
    defaultMessage: 'UI Issues',
  },
  OTHER: {
    id: 'app.Feedback.fields.other',
    defaultMessage: 'Other',
  },
});

export default defineMessages({

  notifications: {
    success: {
      title: {
        id: 'app.components.Notifications.messages.Feedback.success.title',
        defaultMessage: 'SUCCESS',
      },
      body: {
        id: 'app.components.Notifications.messages.Feedback.success.body',
        defaultMessage: 'Thanks for the feedback !'
      }
    },
    error: {
      title: {
        id: 'app.components.Notifications.messages.Feedback.error.title',
        defaultMessage: `Error sending feedback`,
      },
      body: {
        id: 'app.components.Notifications.messages.Feedback.error.body',
        defaultMessage: `Please try sending your feedback again or contact an administrator if the
        issue persists.`,
      },
    },
  },
});

