/**
 * Created by mateimisarca on 21/09/2020
 */

import { shape, arrayOf, number, string, oneOfType, oneOf, node, bool, func } from 'prop-types';

export const NotificationPropType = shape({
    title: oneOfType([string, node]),
    body: oneOfType([string, node]),
    caption: string,
    type: oneOf(['inline', 'toast']),
    kind: oneOf(['error', 'info', 'success', 'warning']),
    role: string,
    iconDescription: string,
    timeout: number,
    canClose: bool,
    onClose: func,
});

const NotificationsPropType = arrayOf(NotificationPropType);

export default NotificationsPropType;
