/**
 * Created by mateimisarca on 21/09/2020
 */

export const REMOVE_HISTORY_NOTIFICATION = 'app/ToastContainer/REMOVE_HISTORY_NOTIFICATION';
export const REMOVE_LATEST_NOTIFICATION = 'app/ToastContainer/REMOVE_LATEST_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'app/ToastContainer/REMOVE_NOTIFICATION';
export const CLEAR_HISTORY_NOTIFICATIONS = 'app/ToastContainer/CLEAR_HISTORY_NOTIFICATIONS';
export const CLEAR_CURRENT_NOTIFICATIONS = 'app/ToastContainer/CLEAR_CURRENT_NOTIFICATIONS';
export const SHOW_NOTIFICATION = 'app/ToastContainer/SHOW_NOTIFICATION';
