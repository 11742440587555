/**
 * Created by mateimisarca on 18/09/2020
 */

import { createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';

import {
    APP_DATA_REQUEST,
    APP_STATE_CLEAR,
    CHANGE_WEBSITE,
} from './constants';

export const appDataRequest = createFormAction(APP_DATA_REQUEST);

export const websiteChange = createAction(CHANGE_WEBSITE);
export const appStateClear = createAction(APP_STATE_CLEAR);


