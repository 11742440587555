/**
 * Created by mateimisarca on 18/09/2020
 */

import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import cx from 'classnames';

export function renderInput({
                              input,
                              placeholder,
                              icon,
                              className,
                              classNameForm,
                              meta,
                              type,
                              error,
                              multiline,
                              disabled,
                              inputFormWidth = 9,
                              as,
                            }) {
  const err = meta.error || error;

  return (
    <Form.Group className={ classNameForm } controlId="formBasicEmail">
      { placeholder && <Form.Label>{ placeholder }</Form.Label> }
      <Form.Control
        { ...input }
        type={ type }
        className={ cx('form-control', className, {
          'form-control--error': err,
        }) }
        disabled={ disabled }
        // id={ input.name }
        name={ input.name }
        placeholder={ placeholder }
        // touched={ meta.touched }
        isInvalid={ err }
        as={ as }
      />
      <Form.Control.Feedback type="invalid">
        { err }
      </Form.Control.Feedback>
    </Form.Group>
  );
}
