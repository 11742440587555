/**
 * Created by mateimisarca on 12.01.2021
 */

import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { cloneDeep, merge, isPlainObject } from 'lodash';
import deepmerge from 'deepmerge';
import update from 'immutability-helper';

// import { filtersRequest } from 'containers/FiltersContainer/actions';

import updateObject from 'utils/updateObject';

import * as pageActions from './actions';
import { productRequest, productsRequest } from '../ProductsContainer/actions';
import { mainPageRequest } from './actions';

export const pageState = {
  key: null,
  path: null,
  name: null,
  title: { id: 'app.pages.loading.title', defaultMessage: ' ' },
  breadcrumbs: [],
  settings: {},
  topArea: {
    buttons: [],
  },
  params: {
    find: '',
  },
  // filters: {},
  data: {
    data: {},
    errored: false,
    noData: false,
  },
  mainPage: {
    products: { rows: [] },
    projects: { rows: [] },
  },
};

export const initialState = {};

const pageContainerReducer = handleActions({
  [LOCATION_CHANGE]: (
    state,
    {
      payload: {
        location: { pathname: page },
      },
    },
  ) => {
    if (!Reflect.has(state, page.replace(/^(\/)|(\/)$/g, '')) && !page.match(/auth\//)) {
      return {
        ...state,
        [page.replace(/^(\/)|(\/)$/g, '')]: Object.assign({}, pageState, {
          path: page.replace(/^(\/)|(\/)$/g, ''),
        }),
      };
    }

    return state;
  },
  [pageActions.pagesClear]: (state, { payload: match = '' }) => {
    return initialState;
  },
  [pageActions.pageClear]: (state, { payload: { page } }) => {
    const newState = cloneDeep(state);
    delete newState[page];
    delete newState[`/${ page }`];
    return newState;
  },
// Props
  [pageActions.pagePropsUpdate]: (state, { payload: { page, payload } }) => {
    if (
      payload?.find !== undefined &&
      state[page]?.params.find !== payload?.find
    ) {
      Object.assign(payload, {
        tableData: {
          ...state[page].tableData,
          isSearching: state[page]?.params.find !== payload?.find,
        },
      });
    }
    return {
      ...state,
      [page]: {
        ...deepmerge(merge(cloneDeep(pageState), state[page]), payload, {
          isMergeableObject: isPlainObject,
        }),
      },
    };
  },
  // Breadcrumbs
  [pageActions.breadcrumbSetActive]: (
    state,
    { payload: { page, breadcrumb } },
  ) => {

    return {
      ...state,
      [page]: {
        ...deepmerge(merge(cloneDeep(pageState), state[page]), { breadcrumbs: breadcrumb }, {
          isMergeableObject: isPlainObject,
        }),
      },
    };
  },
  [pageActions.breadcrumbRemoveActive]: (
    state,
    { payload: { page, idx } },
  ) => ({
    ...state,
    [page]: {
      breadcrumbs: [[idx, state[page].breadcrumbs.length - idx]],
    },
  }),
  [pageActions.breadcrumbClear]: (state, { payload: { page } }) => ({
    ...state,
    [page]: { breadcrumbs: pageState.breadcrumbs },
  }),
  /*
    [filtersRequest.SUCCESS]: (state, { payload }) => {
      const { page, data } = payload;

      if (!state[page]) {
        return update(state, {
          $merge: {
            [page]: {
              filters: data,
            },
          },
        });
      }
      if (!state[page].filters) {
        return update(state, {
          [page]: {
            filters: { $set: data },
          },
        });
      }
      return update(state, {
        [page]: {
          filters: { $set: data },
        },
      });
    },
    [filtersRequest.FAILURE]: (state, { payload: { page } }) => {
      if (!state[page]) {
        return update(state, {
          $merge: {
            [page]: { filters: { $set: pageState.filters } },
          },
        });
      }
      return update(state, {
        [page]: { filters: { $set: pageState.filters } },
      });
    },
  */
  // Page Data
  [pageActions.pageDataRequest.SUCCESS]: (
    state,
    {
      payload: { page, data },
    },
  ) => {
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: {
            data: updateObject(pageState.data, { data }),
          },
        },
      });
    }
    if (!state[page]?.data) {
      return update(state, {
        [page]: {
          data: {
            $set: updateObject(pageState.data, {
              data: updateObject(data || {}),
            }),
          },
        },
      });
    }
    return update(state, {
      [page]: {
        data: {
          $merge: updateObject(pageState.data, {
            data: updateObject(data || {}),
          }),
        },
      },
    });
  },
  [pageActions.pageDataRequest.FAIL]: (state, { payload: { page } }) => {
    if (!state[page]) {
      update(state, {
        $merge: {
          [page]: { $set: updateObject(pageState.data, { errored: true }) },
        },
      });
    }
    return update(state, {
      [page]: { $set: updateObject(pageState.data, { errored: true }) },
    });
  },
  [mainPageRequest.FAILURE]: (state, { payload: { error } }) => Object.assign({}, state, {
    isDataLoading: false,
    error,
  }),
  [mainPageRequest.REQUEST]: (state, { payload }) => Object.assign({}, state, { isDataLoading: true }),
  [mainPageRequest.SUCCESS]: (state, { payload: { data } }) => update(state, {
    mainPage: { $set: data },
    isDataLoading: { $set: false },
  }),

}, initialState);

export default pageContainerReducer;
