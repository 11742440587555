/**
 * Created by mateimisarca on 21/09/2020
 */

import { handleActions } from 'redux-actions';
// import { LOCATION_CHANGE } from 'connected-react-router';
import update from 'immutability-helper';
import updateObject from 'utils/updateObject';

import showNotification, {
    clearCurrentNotifications,
    clearHistoryNotifications,
    removeLatestNotification,
    removeNotification,
    removeHistoryNotification,
} from './actions';

export const initialState = {
    history: [],
    current: [],
};

const notificationsReducer = handleActions({
    // [LOCATION_CHANGE]: state => ({ current: [], history: state.history }),
    [showNotification]: (state, { payload: notification }) => {
        if (state.history.length === 15) {
            // Keep a maximum of 15 notifications for logging purposes in history
            return update(state, {
                current: { $push: [notification] },
                history: { $splice: [[state.history.length - 1, 1, notification]] },
            });
        }
        return update(state, {
            current: { $push: [notification] },
            history: { $push: [notification] },
        });
    },
    [removeLatestNotification]: state => update(state, {
        current: { $splice: [[state.current.length - 1, 1]] },
    }),
    [removeNotification]: (state, { payload: notification }) => update(state, {
        current: { $splice: [[state.current.indexOf(notification), 1]] },
    }),
    [clearCurrentNotifications]: state => updateObject(state, { current: [] }),
    [clearHistoryNotifications]: state => updateObject(state, { history: [] }),
    [removeHistoryNotification]: (state, { payload: notification }) => update(state, {
        history: { $splice: [[state.history.indexOf(notification), 1]] },
    }),
}, initialState);

export default notificationsReducer;
