/**
 * Created by mateimisarca on 24.05.2021
 */

import fetch from 'utils/fetch';
import { API_PATH } from 'components/Modals/FeedbackModal/constants';

const feedbackService = {
  sendFeedback: params =>
    fetch(API_PATH, {
      method: 'POST',
      body: JSON.stringify(params.feedback),
    }),
};

export default feedbackService;
