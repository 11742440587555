/**
 * Created by mateimisarca on 18/09/2020
 */

import { createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';

import { USER_LOGOUT_REQUEST, USER_LOGIN_REQUEST, USER_REGISTER_REQUEST, ACCOUNT_VERIFY } from './constants';

export const loginRequest = createFormAction(USER_LOGIN_REQUEST);
export const registerRequest = createFormAction(USER_REGISTER_REQUEST);
export const logoutRequest = createAction(USER_LOGOUT_REQUEST);
export const accountVerify = createFormAction(ACCOUNT_VERIFY);
