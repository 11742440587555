/**
 * Created by mateimisarca on 21/09/2020
 */

import React, { Component } from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { object, string, func } from 'prop-types';
import { createPortal } from 'react-dom';
import { uniqueId } from 'lodash';

import { makeSelectCurrentLocation } from 'containers/App/selectors';

import Notification from 'components/Notification/Notification';

import noop from 'utils/noop';

import { makeSelectCurrentNotifications, makeSelectLatestNotification } from './selectors';
import { clearCurrentNotifications, removeNotification } from './actions';
import NotificationsPropType from './types';

import './ToastContainer.scss';

const componentRoot = document.getElementById('notification-root');
componentRoot.classList.add('ToastComponent');

const mapStateToProps = state => ({
    page: makeSelectCurrentLocation(state),
    latestNotification: makeSelectLatestNotification(state),
    currentNotifications: makeSelectCurrentNotifications(state),
});

const mapDispatchToProps = dispatch => ({
    clearNotification: idx => dispatch(removeNotification(idx)),
    clearActiveNotifications: () => dispatch(clearCurrentNotifications()),
});

export default @compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)
class ToastContainer extends Component {
    static propTypes = {
        intl: object,
        page: string,
        currentNotifications: NotificationsPropType,
        clearNotification: func,
        clearActiveNotifications: func,
    };

    timeout = [];

    componentDidUpdate(prevProps) {
        const { page: currentPage, clearActiveNotifications } = this.props;
        const { page: prevPage } = prevProps;

        if (currentPage !== prevPage) {
            clearActiveNotifications();
        }
    }

    componentWillUnmount() {
        this.timeout.map(timeout => clearTimeout(timeout));
        this.timeout = [];
    }

    render() {
        const {
            currentNotifications,
            clearNotification,
            intl: { formatMessage, messages },
        } = this.props;

        return currentNotifications.length ? createPortal(
            currentNotifications.map(notification => {
                const {
                    title,
                    kind = 'info',
                    body = '',
                    timeout = 0,
                    type = 'toast',
                    canClose = true,
                    onClose = noop,
                } = notification;

                // const NotificationComponent = NotificationRegistry[type];

                if (
                    !canClose
                    || (type == 'inline' && !isNaN(timeout) && timeout > 0) // eslint-disable-line
                ) {
                    const currentTimeout = this.timeout.length - 1;
                    this.timeout.push(setTimeout(() => {
                        if (this.props.currentNotifications.indexOf(notification) !== -1) { // eslint-disable-line
                            this.timeout.splice(currentTimeout - 1, 1);
                            if (onClose && typeof onClose === 'function') onClose();
                            clearNotification(notification);
                        }
                    }, timeout === 0 ? 3000 : timeout));
                }

                return (
                    <Notification
                        key={ uniqueId(`notification_title_`) }
                        type={ type }
                        kind={ kind }
                        timeout={ timeout }
                        title={ title || formatMessage(messages?.title?.[kind]) }
                        subtitle={ body }
                        hideCloseButton={ !canClose }
                        onClose={ onClose }
                        currentNotifications={ currentNotifications }
                        notification={ notification }
                        clearNotification={ clearNotification }
                    />
                );
            }),
            componentRoot,
        ) : null;
    }
}
