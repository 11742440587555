/**
 * Created by mateimisarca on 02.12.2022
 */

export const PRODUCTS_REQUEST = 'app/Products/GET';
export const PRODUCT_REQUEST = 'app/Products/GET_PRODUCT';
export const PRODUCT_CREATE = 'app/Products/CREATE';
export const PRODUCT_UPDATE = 'app/Products/UPDATE';
export const PRODUCT_PHOTO_ADD = 'app/Products/PHOTO_ADD';
export const PRODUCT_PHOTO_DELETE = 'app/Products/PHOTO_DELETE';
export const CATEGORIES_REQUEST = 'app/Products/GET_CATEGORIES';

export const API_PRODUCTS = '/api/products';
export const API_IMAGES = '/api/images';
