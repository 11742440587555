/**
 * Created by mateimisarca on 21/09/2020
 */

import { createSelector } from 'reselect';

const selectNotifications = state => state.notifications;

const makeSelectNotifications = createSelector(
    selectNotifications,
    (state, filteFn) => {
        if (!filteFn || typeof filteFn !== 'function') return state.history;
        return state.history.filter(filteFn);
    },
);

export const makeSelectLatestNotification = createSelector(
    selectNotifications,
    state => state.current[state.current.length - 1],
);

export const makeSelectCurrentNotifications = createSelector(
    selectNotifications,
    state => state.current,
);

export default makeSelectNotifications;
