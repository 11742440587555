/**
 * Created by mateimisarca on 18.03.2021
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Footer.scss';

const { version } = require('./../../../package.json');

export default class Footer extends Component {
  render() {
    const { constants } = this.props;

    return (
      <footer className="Footer"
              style={ { backgroundColor: constants.colors.footer } }>
        <div className="Footer-footer container">
          <div className="Footer-logoWrapper">
            <img className="Footer-logo" style={ constants.logoStyle } src={ constants.logoFooter } alt="Digitizer" />
          </div>
          <div className="Footer-links">
            <div><Link style={ { color: constants.footerText } } to="/about">Despre Noi</Link></div>
            <div><Link style={ { color: constants.footerText } } to="/terms-and-conditions">Termeni și condiții</Link></div>
            <div><Link style={ { color: constants.footerText } } to="/contact">Contact</Link></div>
          </div>
        </div>
        <div className="Footer-copyright" style={ { backgroundColor: constants.colors.bottomBar } }>
          <span>&#169;2023 - <a href="https://digitizer.ro"
                                target="_blank">Digitizer&#8482;</a> - v{ version }</span>
        </div>
      </footer>
    );
  }
}
