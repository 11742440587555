/**
 * Created by mateimisarca on 18/09/2020
 */

import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import cx from 'classnames';
import { isEqual, cloneDeep, forEach } from 'lodash';

import './dropDown.scss';
import DropdownTree from '../../components/DropdownTree';
import { composeDropdownData } from '../../components/DropdownTree/composeDropdownObject';

export const updateDropdownTreeObject = (data, key, item, singleSelect = false, parent) => {
  let wasFound = false;
  for (const i of data) {
    if (parent !== undefined) {
      i.checked = parent;
      i.partial = false;
    } else if (isEqual(item.id, i.id)) {
      i[key] = item[key];
      if (key === 'checked') i.partial = false;
      if (i.children && i.children.length > 0 && key === 'checked') {
        updateDropdownTreeObject(i.children, key, item, singleSelect, i.checked);
      }
      if (!singleSelect) return true;
      else wasFound = true;
    } else if (i.children && i.children.length > 0) {
      const sonTree = updateDropdownTreeObject(i.children, key, item, singleSelect);
      if (sonTree && key === 'checked') {
        let allTrue = true;
        let allFalse = true;

        forEach(i.children, item => {
          if (!item.checked) {
            allTrue = false;
          } else allFalse = false;
        });

        if (allTrue) {
          i.checked = true;
          i.partial = false;
        } else if (allFalse) {
          i.checked = false;
          i.partial = false;
        } else {
          i.checked = false;
          i.partial = true;
        }
      }
    }

    if (singleSelect && !isEqual(item.id, i.id)) {
      i.checked = false;
      // i.partial = false;
    }
  }

  return wasFound;
};

export function renderDropDown({
                                 input,
                                 placeholder,
                                 icon,
                                 className,
                                 meta,
                                 type,
                                 error,
                                 disabled,
                                 options,
                                 selectOnlyLeaves,
                                 singleSelect = false,
                               }) {
  const err = meta.error || error;
  let dd = input.value || options;
  let checkedArr = [];
  if (input.value) {
    if (typeof input.value === 'number') {
      checkedArr = [input.value];
      dd = options;
    } else if (input.value.length > 0 && !input.value.some(isNaN)) {
      checkedArr = input.value;
      dd = options;
    }
  }
  const data = cloneDeep(composeDropdownData(dd, checkedArr));

  return (
    <Form.Group controlId="formBasicEmail" className="DropdownForm">
      <Form.Label>{ placeholder }</Form.Label>
      <DropdownTree
        id={ input.name }
        keepTreeOnSearch
        keepOpenOnSelect
        showPartiallySelected
        selectOnlyLeaves={ selectOnlyLeaves }
        disabled={ disabled }
        data={ data }
        placeholderText={ placeholder }
        mode={ singleSelect ? 'singleSelect' : 'multiSelect' }
        onChange={ (item, data) => {
          updateDropdownTreeObject(data, 'checked', item, singleSelect);
          input.onChange(data);
        } }
        onNodeToggle={ item => {
          updateDropdownTreeObject(data, 'expanded', item, singleSelect);
          input.onChange(data);
        } }
        error={ err }
      />
      <div className="DropdownForm-invalidFeedback">
        { err }
      </div>
    </Form.Group>
  );
}
