/**
 * Created by mateimisarca on 04.05.2021
 */

import { PureComponent } from 'react';
import { createPortal } from 'react-dom';

const componentRoot = document.getElementById('modal-root');

export default class ModalPortal extends PureComponent {
  render() {
    const { children } = this.props;
    return createPortal(
      children,
      componentRoot,
    );
  }
}
