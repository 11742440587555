/**
 * Created by mateimisarca on 17/09/2020
 */

import React, { PureComponent } from 'react';
import { string, object, element } from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { makeSelectLanguage } from 'containers/App/selectors';
import LanguageProviderWrapper from 'containers/LanguageProvider/LanguageProviderWrapper';

const mapStateToProps = state => ({
    language: makeSelectLanguage(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

export default
@connect(mapStateToProps, mapDispatchToProps)
class LanguageProvider extends PureComponent {
    static propTypes = {
        language: string,
        messages: object,
        children: element,
    };

    render() {
        const { language, messages, children } = this.props;
        return (
            <IntlProvider
                locale={ language }
                key={ language }
                messages={ messages[language] }
                defaultLocale="en"
            >
                <LanguageProviderWrapper>
                    { children }
                </LanguageProviderWrapper>
            </IntlProvider>
        );
    }
}

