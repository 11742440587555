/**
 * Created by mateimisarca on 18/09/2020
 */

import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import deepmerge from 'deepmerge';
import { isPlainObject, merge } from 'lodash';
import update from 'immutability-helper';

import { DEFAULT_LOCALE } from 'containers/LanguageProvider/constants';
import changeLanguage from 'containers/LanguageProvider/actions';
import { pageState } from 'containers/PageContainer/reducer';
import * as pageActions from 'containers/PageContainer/actions';
// import { filtersClearActive, filterSetActive, filtersSetActive } from 'containers/FiltersContainer/actions';

import { appStateClear, appDataRequest } from './actions';
import { DEFAULT_CURRENCY } from '../../constants';

export const initialState = {
  // activeFilters: {},
  userSettings: {
    language: DEFAULT_LOCALE,
    currency: DEFAULT_CURRENCY,
  },
  page: pageState,
  currency: [],
  icons: []
};

const appStateReducer = handleActions(
  {
    [LOCATION_CHANGE]: (
      state,
      {
        payload: {
          location: pathname,
        },
      },
    ) => {
      const { pathname: page } = pathname;
      if (
        !Reflect.has(state, page) &&
        !page.match(/auth\//)
      ) {
        return Object.assign({}, state, {
          pathname,
        });
      }
      return state;
    },
    [appStateClear]: () => initialState,
    /* Language */
    [changeLanguage]: (state, { payload: language }) =>
      Object.assign({}, state, {
        userSettings: { language },
      }),
    [pageActions.pagePropsUpdate]: (state, { payload: { payload } }) => ({
      ...state,
      page: {
        ...deepmerge(state.page, payload, {
          isMergeableObject: isPlainObject,
        }),
      },
    }),
    [appDataRequest.SUCCESS]: (state, { payload: { currency, icons } }) => {
      if (!state.userSettings) {
        return update(state, {
          currency: {
            $set: currency,
          },
          icons: {
            $set: icons,
          },
        });
      }

      return {
        ...state,
      };
    },
/*
    /!* Filters *!/
    [filtersSetActive]: (state, { payload: { filters } }) => update(state, {
      activeFilters: {
        $merge: filters,
      },
    }),
    [filterSetActive]: (state, { payload: { filter } }) => update(state, {
      activeFilters: {
        $merge: filter,
      },
    }),
    [filtersClearActive]: state => update(state, {
      activeFilters: {
        $set: {},
      },
    }),
*/
  },
  initialState,
);

export default appStateReducer;
