/**
 * Created by mateimisarca on 18.01.2021
 */

import React, { Component } from 'react';
import cx from 'classnames';

import './Icon.scss';

export default class Icon extends Component {
  render() {
    const {
      icon,
      iconTitle,
      onClick,
    } = this.props;

    return (
      <i className={ cx('Icon', icon) } onClick={ onClick }>
        <span className="Icon-tooltip">{ iconTitle }</span>
      </i>
    );
  }
}
