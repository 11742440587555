/**
 * Created by mateimisarca on 18/09/2020
 */

import { required, email, match } from 'utils/validations';

const registerValidation = (data) => {
    return {
        firstName: [required],
        lastName: [required],
        email: [required, email],
        password: [required],
        passwordConfirmation: [required],//, match('password')],
        tc: [required],
    };
};

export default registerValidation;