/**
 * Created by mateimisarca on 17/09/2020
 */

import { reducer } from 'redux-storage';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import appStateReducer from 'containers/App/reducer';
import notificationsReducer from 'containers/ToastContainer/reducer';
import authReducer from 'containers/AuthContainer/reducer';
import userReducer from 'containers/UserContainer/reducer';
import pageReducer from 'containers/PageContainer/reducer';
import productsReducer from 'containers/ProductsContainer/reducer';

import feedbackModalReducer from 'components/Modals/FeedbackModal/reducer';

import uiStateReducer from './uiState';

export default (history, injectedReducers) =>
  reducer(
    combineReducers({
      router: connectRouter(history),
      appState: appStateReducer,
      page: pageReducer,
      uiState: uiStateReducer,
      notifications: notificationsReducer,
      auth: authReducer,
      user: userReducer,
      modalsFeedback: feedbackModalReducer,
      products: productsReducer,
      form,
      ...injectedReducers,
    }),
  );
