/**
 * Created by mateimisarca on 18/09/2020
 */

import { takeLatest, call, put } from 'redux-saga/effects';
import fetch from 'utils/fetch';
import logger from 'utils/logger';

import { accountVerify, loginRequest, registerRequest } from 'containers/AuthContainer/actions';

import { errorMessages } from './messages';
import { API_AUTH, API_REGISTER, API_VERIFY_ACCOUNT } from './constants';
import showNotification from '../ToastContainer/actions';
import FormattedMessage from '../../components/FormattedMessage';
import messages from 'containers/PageContainer/messages';
import React from 'react';

function* authorize({ payload: { email, password } }) {
  const options = {
    method: 'POST',
    body: JSON.stringify({ email, password }),
  };

  try {
    const data = yield call(fetch, API_AUTH, options);
    yield put(loginRequest.success(data));
  } catch (error) {
    yield put(
      loginRequest.failure({
        error: Error(errorMessages[JSON.parse(error).message]?.id || 'ERROR'),
      }),
    );
    logger.error(error);
  }
}


function* register({ payload }) {
  const options = {
    method: 'POST',
    body: JSON.stringify({ ...payload }),
  };

  try {
    const data = yield call(fetch, API_REGISTER, options);
    yield put(registerRequest.success(data));
  } catch (error) {
    const page = 'Register';
    yield put(
      showNotification({
        title: (
          <FormattedMessage { ...messages.notifications.data.error.title } />
        ),
        body: (
          <FormattedMessage
            { ...messages.notifications.data.error.body }
            values={ { page: <strong>{ page }</strong> } }
          />
        ),
        kind: 'error',
      }),
    );
    yield put(
      registerRequest.failure({
        error: Error(errorMessages[JSON.parse(error).message].id),
      }),
    );
    logger.error(error);
  }
}

function* verifyToken({ payload }) {
  const options = {
    method: 'POST',
    body: JSON.stringify({ ...payload }),
  };

  try {
    const data = yield call(fetch, API_VERIFY_ACCOUNT, options);
    yield put(accountVerify.success(data));
  } catch (error) {
    const page = 'accountVerify';
    yield put(
      showNotification({
        title: (
          <FormattedMessage { ...messages.notifications.data.error.title } />
        ),
        body: (
          <FormattedMessage
            { ...messages.notifications.data.error.body }
            values={ { page: <strong>{ page }</strong> } }
          />
        ),
        kind: 'error',
      }),
    );
    yield put(
      accountVerify.failure({
        error: Error(errorMessages[JSON.parse(error)?.message]?.id || JSON.parse(error).message),
      }),
    );
    logger.error(error);
  }
}

// Default export for testing
export default function* sagas() {
  yield takeLatest(loginRequest.REQUEST, authorize);
  yield takeLatest(registerRequest.REQUEST, register);
}

export const authSagas = [
  takeLatest(loginRequest.REQUEST, authorize),
  takeLatest(registerRequest.REQUEST, register),
  takeLatest(accountVerify.REQUEST, verifyToken),
];
