/**
 * Created by mateimisarca on 18/09/2020
 */

import { createAction } from 'redux-actions';
import { CHANGE_LOCALE } from './constants';

const changeLanguage = createAction(CHANGE_LOCALE);

export default changeLanguage;

