/**
 * Created by mateimisarca on 01.02.2021
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.ErrorBoundary.title',
    defaultMessage: 'Oops!',
  },
  body: {
    id: 'app.components.ErrorBoundary.body',
    defaultMessage: `An unexpected error occurred. We've notified an administrator about the issue`,
  },
  back: {
    id: 'app.components.ErrorBoundary.back',
    defaultMessage: 'Go back ?',
  },
});

