/**
 * Created by mateimisarca on 09.07.2021
 */

import { LOCATION_CHANGE } from 'connected-react-router';
import ReactGA from 'react-ga4';

import { pageView } from '../actions/uiActions';

export default function routeChange() {
  return ({ dispatch, getState }) =>
    next => (action) => {
      if (action.type === LOCATION_CHANGE) {
        console.info(`Route Changed: ${ action.payload.location.pathname }`);
        pageView({
          payload: {
            ...action.payload,
            type: 'ENTER',
          },
        }, dispatch);
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
      }
      return next(action);
    };
}
