/**
 * Created by mateimisarca on 24.05.2021
 */

import { createSelector } from 'reselect';

const selectFeedbackContainerDomain = state => state.modalsFeedback;

const makeSelectFeedbackModalOpen = createSelector(
  selectFeedbackContainerDomain,
  state => state.open,
);

export default makeSelectFeedbackModalOpen;

