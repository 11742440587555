/**
 * Created by mateimisarca on 21/09/2020
 */

import { handleActions, combineActions } from 'redux-actions';

import { getUserRequest } from './actions';
import { logoutRequest } from '../AuthContainer/actions';

export const initialUserState = {
    firstName: '',
    lastName: '',
    shortName: null,
    email: '',
    photo: '',
    role: null,
};

const userReducer = handleActions({
    [getUserRequest.SUCCESS]: (state, { payload: { result } }) => result,
    [combineActions(getUserRequest.FAILURE, logoutRequest)]: () => initialUserState,
}, initialUserState);

export default userReducer;
