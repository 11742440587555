/**
 * Created by mateimisarca on 18/09/2020
 */

import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

export function renderCheck({ input, label, icon, className, meta, type, error, multiline }) {
  const err = meta.error || error;

  return (
    <Form.Group as={ Row } controlId={ input.name }>
      <Col sm={ { span: 9, offset: 3 } }>
        <Form.Check
          { ...input }
          label={ label }
          name={ input.name }
          isInvalid={ meta.error || error }
          feedback={ err }
          checked={ input.value }
        />
      </Col>
    </Form.Group>
  );
}
