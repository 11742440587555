/**
 * Created by mateimisarca on 14.01.2021
 */

import { isEqual } from 'lodash';
import { createSelector } from 'reselect';

import { makeSelectCurrentLocation } from 'containers/App/selectors';

import { pageState } from './reducer';

const selectPageContainerDomain = state => state.page;
const selectAppStateDomain = state => state.appState;

export const makeSelectPageBreadcrumbs = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.breadcrumbs || pageState.breadcrumbs,
);

export const makeSelectPageFilters = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.filters || pageState.filters,
);

export const makeSelectPageParams = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  selectAppStateDomain,
  (location, page, appState) => {
    if (
      isEqual(location, appState.page.path) &&
      !isEqual(page[location]?.params, appState.page?.params)
    ) {
      return appState.page?.params || pageState.params;
    }
    return page[location]?.params || pageState.params;
  },
);

export const makeSelectPageData = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.data || pageState.data,
);

export const makeSelectMainPageData = createSelector(
  selectPageContainerDomain,
  page => page.mainPage)
