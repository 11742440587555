/**
 * Created by mateimisarca on 17/09/2020
 */

import { initialState as appState } from 'containers/App/reducer';
// import { initialUserState as userState } from 'containers/UserContainer/reducer';
// import { initialState as authState } from 'pages/Login/reducer';
import { uiState } from 'reducers/uiState';

const initialState = {
    appState,
    uiState,
};

export default initialState;

