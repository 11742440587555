/**
 * Created by mateimisarca on 17/09/2020
 */

/* eslint-disable no-console */

// TODO: output warn and error log to file

const chalk = require('chalk');
const ip = require('ip');

const divider = chalk.gray('\n-----------------------------------');

const logger = {
    log: info => {
        console.log(info);
        // console.log(`%c${info}`, `color: blue`);
    },

    warn: msg => {
        console.warn(msg);
        // console.log(chalk.yellow(msg));
    },

    error: err => {
        console.error(err);
        // console.error(chalk.red(err));
    },

    // Called when express.js app starts on given port w/o errors
    appStarted: (port, host, tunnelStarted) => {
        console.log(`Server started ! ${chalk.green('✓')}`);

        // If the tunnel started, log that and the URL it's available at
        if (tunnelStarted) {
            console.log(`Tunnel initialised ${chalk.green('✓')}`);
        }

        console.log(`
      ${chalk.bold('Access URLs:')}${divider}
      Localhost: ${chalk.magenta(`http://${host}:${port}`)}
      LAN: ${chalk.magenta(`http://${ip.address()}:${port}`)}
      ${
            process.env.USE_PROXY === 'true'
                ? `Proxy ${chalk.magenta(`${process.env.PROXY_URI}`)}`
                : ''
        }
      ${divider}
      ${chalk.blue(`Press ${chalk.italic('CTRL-C')} to stop`)}
    `);
    },
};

module.exports = logger;

