/**
 * Created by mateimisarca on 01.02.2021
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { makeSelectCurrentLocation } from 'containers/App/selectors';
import { pageClear } from 'containers/PageContainer/actions';

import FormattedMessage from 'components/FormattedMessage';
import feedbackService from 'components/Modals/FeedbackModal/service';

import messages from './messages';

const mapStateToProps = state => ({
  page: makeSelectCurrentLocation(state),
});

const mapDispatchToProps = dispatch => ({
  goTo: page => dispatch(push(page)),
  clearPage: page => dispatch(pageClear(page)),
});

export default @compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)
class ErrorBoundary extends Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // ToDo: enable this
    const {
      location: { pathname, search },
      page,
      pageSettings,
      pageParams,
    } = this.props;

    const payload = {
      settings: pageSettings,
      params: pageParams,
    };
    if (process.env.NODE_ENV === 'production') {
      feedbackService.sendFeedback({
        feedback: {
          category: 'UI_ISSUE',
          feedback: 'Runtime Error',
          data: { page, payload, error, errorInfo },
          url: `${ window.location.origin }${ pathname }${ search }`,
        },
      });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children, goTo, page } = this.props;

    if (hasError) {
      return (
        <div
          style={ {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          } }
        >
          <h1
            style={ {
              fontSize: '4.75rem',
              fontWeight: 400,
              margin: '2rem 0 1.5rem',
            } }
          >
            <FormattedMessage { ...messages.title } />
          </h1>
          <h2 style={ { fontSize: '1.5rem', marginBottom: '1.5rem' } }>
            <FormattedMessage { ...messages.body } />
          </h2>
          <FormattedMessage { ...messages.back }>
            { txt => (
              <a
                style={ {
                  textDecoration: 'none',
                  fontSize: '1rem',
                  color: '#3D70B2',
                  display: 'block',
                } }
                href={ page }
                title={ page }
                onClick={ e => {
                  e.preventDefault();
                  this.setState({ hasError: false });
                  goTo(page);
                } }
              >
                { txt }
              </a>
            ) }
          </FormattedMessage>
        </div>
      );
    }

    return children;
  }
}
