/**
 * Created by mateimisarca on 17/09/2020
 */

import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { UI_IS_NAVIGATION_OPEN, UI_APP_WIDTH } from 'constants/index';
import { resetUi, updateUi } from 'actions/uiActions';
import { constants } from '../constants/config';

export const uiState = {
  [UI_IS_NAVIGATION_OPEN]: false,
  [UI_APP_WIDTH]: 0,
  constants: constants[process.env.REACT_APP_INSTANCE],
};

const uiStateReducer = handleActions({
  [updateUi]: (state, { payload }) => update(state, { $merge: payload }),
  [resetUi]: () => uiState,
}, uiState);

export default uiStateReducer;

