/**
 * Created by mateimisarca on 17/09/2020
 */

import { hot } from 'react-hot-loader/root';

import React, { Suspense, Fragment, PureComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { bool, string, object, func } from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { includes } from 'lodash';
import cx from 'classnames';

import Loading from 'components/Loading';
import FeedbackModal from 'components/Modals/FeedbackModal/FeedbackModal';

import { makeSelectUser } from 'containers/UserContainer/selectors';
import { makeSelectLoggedIn } from 'containers/AuthContainer/selectors';
import { makeSelectCurrentLocation, makeSelectLocation } from './selectors';
import { logoutRequest } from 'containers/AuthContainer/actions';
import { appStateClear } from './actions';
import AuthContainer from 'containers/AuthContainer';
// import { appStateClear } from 'containers/App/actions';
import { getUserRequest } from 'containers/UserContainer/actions';
import { USER_LOGOUT_REQUEST } from 'containers/AuthContainer/constants';
import ToastContainer from 'containers/ToastContainer/ToastContainer';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import TopMenu from 'components/TopMenu';

import P404 from 'pages/Errors/P404/P404';

import { pageView } from 'actions/uiActions';
import fetch from 'utils/fetch';

import { JWT_KEY } from '../../constants';

import './App.scss';

import { API_PAGE_VIEW, API_PATH_DATA } from './constants';
import Footer from '../../components/Footer';
import makeSelectUiState from '../../selectors/uiSelectors';

const PageContainer = loadable(() =>
  import(/* webpackPrefetch: true */ 'containers/PageContainer'),
);

const mapStateToProps = state => ({
  ui: makeSelectUiState(state),
  loggedIn: makeSelectLoggedIn(state),
  user: makeSelectUser(state),
// userAcceptedConsent: makeSelectUserConsent(state),
  location: makeSelectLocation(state),
  page: makeSelectCurrentLocation(state),
});

const mapDispatchToProps = dispatch => ({
  leavePage: payload => pageView({ payload }, dispatch),
  clearAppState: () => dispatch(appStateClear()),
  logoutUser: err => dispatch(logoutRequest(err)),
  getUserInfo: () => getUserRequest(null, dispatch),
  goTo: page => dispatch(push(page)),
});

export default @compose(
  hot,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)

class App extends PureComponent {
  static propTypes = {
    loggedIn: bool,
    page: string,
    location: object,
    getUserInfo: func,
    clearAppState: func,
    logoutUser: func,
    goTo: func,
    leavePage: func,
  };

  componentDidMount() {
    const {
      loggedIn,
      getUserInfo,
      logoutUser,
      // userAcceptedConsent,
      goTo,
      leavePage,
      location,
    } = this.props;

    window.onbeforeunload = (event) => {
      const e = event || window.event;

      fetch(API_PAGE_VIEW, {
        method: 'POST',
        body: JSON.stringify({
          type: 'LEAVE',
          event: e,
          location,
        }),
      });
    };

    // Register LOGOUT_REQUEST cb event handler Log out user on session expiry
    document.addEventListener(USER_LOGOUT_REQUEST, e => {
      localStorage.removeItem(JWT_KEY);
      logoutUser(e.detail);
    });

    if (loggedIn) {
      getUserInfo();
    } /*else if (!includes(location.pathname, '/auth')) {
      goTo('/auth');
    }*/
  }

  componentDidUpdate(prevProps) {
    const { loggedIn: wasLoggedIn, user: prevUser } = prevProps;
    const {
      loggedIn: isLoggedIn,
      user: currentUser,
      getUserInfo,
      clearAppState,
    } = this.props;

    if (prevUser.email !== currentUser.email && prevUser.email) {
      clearAppState();
    }

    if (wasLoggedIn === false && isLoggedIn) {
      getUserInfo();
    }
  }

  render() {
    const { loggedIn, location: { pathname }, ui: { constants } } = this.props;

    return (
      <Fragment>
        <ErrorBoundary>
          <ToastContainer />
          <TopMenu isLoggedIn={ loggedIn } />
          {/*<FeedbackModal />*/ }
          <Container fluid className={ cx('App-Container', {
            // 'container': !includes(pathname, 'admin'),
            'Container--scroll': includes(pathname, 'auth'),
          }) }>
            <Switch>
              <Route
                path="/"
                render={ () => {
                  // if (loggedIn && user.name && user.email) {
                  // if (loggedIn) {
                  return (
                    <Suspense fallback={ <Loading active /> }>
                      <ConfirmationModal>
                        <PageContainer loggedIn={ loggedIn } />
                      </ConfirmationModal>
                    </Suspense>
                  );
                  // }
                  // return <Redirect to="/auth" />;
                } }
              />
              <Route component={ P404 } />
            </Switch>
          </Container>
          <Footer constants={ constants } />
        </ErrorBoundary>
      </Fragment>
    );
  }
}

// export default hot(App);
