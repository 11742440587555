/**
 * Created by mateimisarca on 02.12.2022
 */

import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { categoriesRequest, productRequest, productsRequest } from './actions';

export const initialState = {
  isDataLoading: false,
  products: {
    count: 0,
    rows: [],
    currentPage: 1,
    pages: 1,
    itemsPerPage: [10, 20, 50],
    perPage: 10,
  },
  product: null,
  categories: [],
};

const productsReducer = handleActions({
  [productsRequest.REQUEST]: (state, { payload }) => Object.assign({}, state, { isDataLoading: true }),
  [productsRequest.SUCCESS]: (state, { payload: { result } }) => update(state, {
    products: { $set: result },
    isDataLoading:  { $set: false },
  }),
  [productsRequest.FAILURE]: (state, { payload: { error } }) => Object.assign({}, state, { isDataLoading: false, error }),
  [productRequest.REQUEST]: (state, { payload }) => Object.assign({}, state, { isDataLoading: true }),
  [productRequest.SUCCESS]: (state, { payload: { result } }) => update(state, {
    product: { $set: result },
    isDataLoading:  { $set: false },
  }),
  [productRequest.FAILURE]: (state, { payload: { error } }) => Object.assign({}, state, { isDataLoading: false, error }),
  [categoriesRequest.REQUEST]: (state, { payload }) => Object.assign({}, state, { isDataLoading: true }),
  [categoriesRequest.SUCCESS]: (state, { payload }) => Object.assign({}, state, {
    isDataLoading: false,
    categories: payload?.result,
  }),
  [categoriesRequest.FAILURE]: (state, { payload: { error } }) => Object.assign({}, state, { isDataLoading: false, error }),
}, initialState);

export default productsReducer;