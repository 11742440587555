/**
 * Created by mateimisarca on 21/09/2020
 */

import { string, shape } from 'prop-types';

const PagePropTypes = shape({
    key: string,
    name: string,
    path: string,
});

export default PagePropTypes;
