/**
 * Created by mateimisarca on 02.12.2022
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import fetch from 'utils/fetch';
import logger from 'utils/logger';

import { API_IMAGES, API_PRODUCTS } from './constants';
import {
  categoriesRequest,
  productCreate,
  productPhotoAdd,
  productPhotoDelete,
  productRequest,
  productsRequest, productUpdate,
} from './actions';

function* getProducts({ payload: { category, PATH = API_PRODUCTS, ...rest } }) {
  try {
    const products = yield call(fetch, PATH, {
      ...rest,
    });
    yield put(productsRequest.success(products));
  } catch (error) {
    yield put(productsRequest.failure({ error }));
    if (error?.payload?.action) {
      yield put(error.payload.action);
    }
    logger.error(error);
  }
}

function* getProduct({ payload: { productId, PATH = API_PRODUCTS, ...rest } }) {
  try {
    const product = yield call(fetch, `${ PATH }/${ productId }`, {
      ...rest,
    });
    yield put(productRequest.success(product));
  } catch (error) {
    yield put(productRequest.failure({ error }));
    if (error?.payload?.action) {
      yield put(error.payload.action);
    }
    logger.error(error);
  }
}

function* createProduct({ payload: { values, PATH = API_PRODUCTS, ...rest } }) {
  try {
    const product = yield call(fetch, PATH, {
      method: 'POST',
      body: JSON.stringify(values),
      ...rest,
    });
    yield put(productCreate.success(product));
  } catch (error) {
    yield put(productCreate.failure({ error }));
    if (error?.payload?.action) {
      yield put(error.payload.action);
    }
    logger.error(error);
  }
}

function* updateProduct({ payload: { values, PATH = API_PRODUCTS, ...rest } }) {
  try {
    if (!values.id) throw {
      error: 'Missing ID',
    };

    const product = yield call(fetch, `${ PATH }/${ values.id }`, {
      method: 'PUT',
      body: JSON.stringify(values),
      ...rest,
    });
    yield put(productUpdate.success(product));
  } catch (error) {
    yield put(productUpdate.failure({ error }));
    if (error?.payload?.action) {
      yield put(error.payload.action);
    }
    logger.error(error);
  }
}

function* savePhoto({ payload: { photo, id, PATH = API_IMAGES, ...rest } }) {
  try {
    const product = yield call(fetch, PATH, {
      method: 'POST',
      body: JSON.stringify({ photo, id }),
      ...rest,
    });
    yield put(productPhotoAdd.success(product));
  } catch (error) {
    yield put(productPhotoAdd.failure({ error }));
    if (error?.payload?.action) {
      yield put(error.payload.action);
    }
    logger.error(error);
  }
}

function* deletePhoto({ payload: { id, PATH = API_IMAGES, ...rest } }) {
  try {
    const product = yield call(fetch, `${ PATH }/${ id }`, {
      method: 'DELETE',
      ...rest,
    });
    yield put(productPhotoDelete.success(product));
  } catch (error) {
    yield put(productPhotoDelete.failure({ error }));
    if (error?.payload?.action) {
      yield put(error.payload.action);
    }
    logger.error(error);
  }
}

function* getCategories({ payload: { payload, PATH = API_PRODUCTS, ...rest } }) {
  try {
    const products = yield call(fetch, `${ PATH }/categories`, {
      ...rest,
    });
    yield put(categoriesRequest.success(products));
  } catch (error) {
    yield put(categoriesRequest.failure({ error }));
    if (error?.payload?.action) {
      yield put(error.payload.action);
    }
    logger.error(error);
  }
}

export const productsSagas = [
  takeLatest(productsRequest.REQUEST, getProducts),
  takeLatest(productRequest.REQUEST, getProduct),
  takeLatest(productCreate.REQUEST, createProduct),
  takeLatest(productUpdate.REQUEST, updateProduct),
  takeLatest(productPhotoAdd.REQUEST, savePhoto),
  takeLatest(productPhotoDelete.REQUEST, deletePhoto),
  takeLatest(categoriesRequest.REQUEST, getCategories),
];