/**
 * Created by mateimisarca on 02.12.2022
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEqual, remove } from 'lodash';
import cx from 'classnames';
import { isMobileOnly } from 'react-device-detect';

import { ROLES } from '../../constants/roles';
import { makeSelectLoggedIn } from '../../containers/AuthContainer/selectors';
import { makeSelectUser } from '../../containers/UserContainer/selectors';

import './Menu.scss';

const menus = [
  {
    link: '/',
    title: 'Acasă',
  },
  {
    link: '/about-us',
    title: 'Despre Noi',
  },
  {
    link: '/products',
    title: 'Servicii',
  },
  {
    link: '/projects',
    title: 'Proiecte',
  },
  {
    link: '/contact',
    title: 'Contact',
  },
];

const adminMenus = [
  {
    link: '/admin',
    title: 'Admin',
  },
];

const mapStateToProps = (state) => ({
  isLoggedIn: makeSelectLoggedIn(state),
  profile: makeSelectUser(state),
});

export default @connect(mapStateToProps)
class Menu extends Component {
  constructor(props) {
    super(props);

    let topMenus = (props?.profile?.role === ROLES.ADMIN || props?.profile?.role === ROLES.SUPER_ADMIN) ? [...menus, ...adminMenus] : menus;
    if (process.env.REACT_APP_INSTANCE === 'IDEAPAPEL') {
      remove(topMenus, tm => tm.title === 'Proiecte');
    }

    this.state = {
      menus: topMenus,
    };
  }

  componentDidMount() {
    const { profile } = this.props;

    const topMenus = (profile?.role === ROLES.ADMIN || profile?.role === ROLES.SUPER_ADMIN) ? [...menus, ...adminMenus] : menus;
    this.setState({
      menus: topMenus,
    });
  }

  componentDidUpdate(prevProps) {
    const { profile } = this.props;
    const { profile: oldProfile } = prevProps;

    if (!isEqual(profile, oldProfile)) {
      const topMenus = (profile?.role === ROLES.ADMIN || profile?.role === ROLES.SUPER_ADMIN) ? [...menus, ...adminMenus] : menus;
      this.setState({
        menus: topMenus,
      });
    }
  }

  render() {
    return (
      <nav id="main-menu" className={ cx('main-menu mainMenu', {
        'mainMenu--mobile': isMobileOnly,
      }) }>
        <ul className="mainMenu-list">
          { this.state.menus.map((menu, index) => (
            <li key={ index }>
              <Link to={ menu.link }>{ menu.title }</Link>
            </li>
          )) }
        </ul>
      </nav>
    );
  }
}
