/**
 * Created by mateimisarca on 22/09/2020
 */

import React, { Component } from 'react';
import { FormattedMessage as FormattedMsg } from 'react-intl';
import { string, func } from 'prop-types';

export default class FormattedMessage extends Component {
    static propTypes = {
        children: func,
        id: string,
        defaultMessage: string,
    };

    static defaultProps = {
        id: '',
        defaultMessage: '',
    };

    render() {
        const { id, defaultMessage } = this.props;
        if (!id || id === '' || defaultMessage === '') {
            console.warn('No Message provided');
            return '';
        }

        return <FormattedMsg { ...this.props } />;
    }
}
