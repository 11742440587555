/**
 * Created by mateimisarca on 21.04.2021
 */

import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';

import showNotification from 'containers/ToastContainer/actions';
import messages from 'containers/PageContainer/messages';

import FormattedMessage from 'components/FormattedMessage';

import fetch from 'utils/fetch';
import logger from 'utils/logger';

import { API_MESSAGE, API_PAGE_VIEW, API_PATH_DATA } from './constants';
import { appDataRequest } from './actions';
import { pageView } from '../../actions/uiActions';
import { addMessage } from '../PageContainer/actions';

function* getAppData({ payload: { websiteId, PATH = API_PATH_DATA, ...rest } }) {
  try {
    const menus = yield call(fetch, PATH, {
      ...rest,
    });
    yield put(appDataRequest.success(menus));
  } catch (error) {
    const page = 'Menus';
    yield put(
      showNotification({
        title: (
          <FormattedMessage { ...messages.notifications.data.error.title } />
        ),
        body: (
          <FormattedMessage
            { ...messages.notifications.data.error.body }
            values={ { page: <strong>{ page }</strong> } }
          />
        ),
        kind: 'error',
      }),
    );

    yield put(appDataRequest.failure({ error }));
    if (error?.payload?.action) {
      yield put(error.payload.action);
    }
    logger.error(error);
  }
}

function* sendPageView({ payload: { payload, PATH = API_PAGE_VIEW, ...rest } }) {
  try {
    const menus = yield call(fetch, PATH, {
      method: 'POST',
      body: JSON.stringify(payload),
      ...rest,
    });
    yield put(pageView.success(menus));
  } catch (error) {
    yield put(pageView.failure({ error }));
    if (error?.payload?.action) {
      yield put(error.payload.action);
    }
    logger.error(error);
  }
}

function* sendMessage({ payload: { payload, PATH = API_MESSAGE, ...rest } }) {
  try {
    const menus = yield call(fetch, PATH, {
      method: 'POST',
      body: JSON.stringify(payload),
      ...rest,
    });
    yield put(addMessage.success(menus));
  } catch (error) {
    yield put(addMessage.failure({ error }));
    if (error?.payload?.action) {
      yield put(error.payload.action);
    }
    logger.error(error);
  }
}

export default function* () {
  yield takeLatest(appDataRequest.REQUEST, getAppData);
}
export const appSagas = [
  takeLatest(appDataRequest.REQUEST, getAppData),
  takeLatest(pageView.REQUEST, sendPageView),
  takeLatest(addMessage.REQUEST, sendMessage),
];
