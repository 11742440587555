/**
 * Created by mateimisarca on 20.05.2021
 */

import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import cx from 'classnames';
import { func, node, oneOf, string, bool } from 'prop-types';
import { isEqual } from 'lodash';

import noop from 'utils/noop';

import './Button.scss';

export default class ButtonComponent extends Component {
  static propTypes = {
    icon: string,
    children: node,
    onClick: func,
    variant: oneOf(['primary', 'secondary', 'danger']),
    disabled: bool,
    type: string,
  };

  static defaultProps = {
    label: 'BUTTON',
    onClick: noop,
    variant: 'primary',
    disabled: false,
    type: null,
  };

  render() {
    const { icon, label, children, onClick, variant, disabled, type } = this.props;

    return (
      <Button
        className={ cx('Button', {
          'Button--primary': isEqual(variant, 'primary'),
          'Button--secondary': isEqual(variant, 'secondary'),
          'Button--danger': isEqual(variant, 'danger'),
          'Button--disabled': disabled,
        }) }
        onClick={ onClick }
        disabled={ disabled }
        type={ type }
      >
        { icon && <i className={ cx(icon, 'Button-icon') } /> }
        <span>{ children || label }</span>
      </Button>
    );
  }
}
