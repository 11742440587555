/**
 * Created by mateimisarca on 18/09/2020
 */

import { defineMessages } from 'react-intl';

export const errorMessages = defineMessages({
    missing: {
        username: {
            id: 'app.components.Login.missing.username',
            defaultMessage: `Email field can't be empty`,
        },
        password: {
            id: 'app.components.Login.missing.username',
            defaultMessage: `Password field can't be empty`,
        },
    },
    invalid: {
        username: {
            id: 'app.components.Login.invalid.username',
            defaultMessage: `That is not a valid email address!`,
        },
    },
    credentials: {
        id: 'app.components.Login.errors.credentials',
        defaultMessage: `Invalid credentials. Please try again.`,
    },
    session: {
        id: 'app.components.Login.errors.session',
        defaultMessage: `Your session expired. Please login again.`,
    },
    server: {
        id: 'app.components.Login.errors.server',
        defaultMessage: `Server internal error. Please try logging in again and inform an
    administrator if the issue persists.`,
    },
    INCORRECT_PASSWORD: {
        id: 'app.components.Login.errors.INCORRECT_PASSWORD',
        defaultMessage: 'Authentication Error: Incorrect Password',
    },
    INVALID_USERNAME: {
        id: 'app.components.Login.errors.INVALID_USERNAME',
        defaultMessage: 'Authentication Error: Invalid Username',
    },
    LOGIN_ERROR: {
        id: 'app.components.Login.errors.LOGIN_ERROR',
        defaultMessage: 'Authentication Error: Login Error',
    },
    NOT_MEMBER: {
        id: 'app.components.Login.errors.NOT_MEMBER',
        defaultMessage: 'Authentication Error: No Access',
    },
    AUTHORIZATION_ERROR: {
        id: 'app.components.Login.errors.AUTHORIZATION_ERROR',
        defaultMessage: 'Authentication Error',
    },
    NOT_CONFIRMED: {
        id: 'app.components.Login.errors.AUTHORIZATION_ERROR',
        defaultMessage: 'Email not confirmed',
    }
});

