/**
 * Created by mateimisarca on 17/09/2020
 */

import enTranslationMessages from './translations/en.json';
import roTranslationMessages from './translations/ro.json';

export const appLocales = ['en', 'ro'];

export const translationMessages = {
    en: enTranslationMessages,
    ro: roTranslationMessages,
};

