/**
 * Created by mateimisarca on 04.02.2021
 */

import React, { Fragment, cloneElement, Children, isValidElement } from 'react';
import { Modal } from 'react-bootstrap';
import { useMachine } from 'react-robot';

import Button from 'components/Button';

import { confirmationFlow } from './confirmationFlow';
import { injectIntl } from 'react-intl';

const messagesKeys = messages => ({
  DELETE: {
    title: messages.generic.delete.title,
    body: messages.generic.delete.body,
    mainBtn: messages.generic.delete.delete,
    variant: 'danger',
  },
  TEMPLATE_SELECT: {
    title: messages.generic.select,
    body: messages.generic.templateSelection,
    mainBtn: messages.generic.select,
    variant: 'primary',
  },
});

const ConfirmationModal = props => {
  const {
    children,
    intl: {
      formatMessage,
      messages,
    },
  } = props;
  const [current, send] = useMachine(confirmationFlow);
  const isLoading = current.name === 'loading';
  const confirmationType = current?.context?.confirmationType || 'DELETE'

  return (
    <Fragment>
      { Children.map(children, child => {
        if (isValidElement(child)) {
          return cloneElement(child, { send });
        }
        return child;
      }) }
      <Modal
        show={
          current.name === 'confirming' ||
          current.name === 'loading'
        }
        onHide={ () => send('cancel') }
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ElementsModal"
      >
        <Modal.Header closeButton={ true }>
          <Modal.Title id="contained-modal-title-vcenter">
            { formatMessage(messagesKeys(messages)[confirmationType].title) }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { formatMessage(messagesKeys(messages)[confirmationType].body) }
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={ isLoading } variant="secondary" onClick={ () => send('cancel') }>
            { formatMessage(messages.generic.cancel) }
          </Button>
          <Button disabled={ isLoading } variant={messagesKeys(messages)[confirmationType].variant} onClick={ () => send('confirm') }>
            { formatMessage(messagesKeys(messages)[confirmationType].mainBtn) }
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default injectIntl(ConfirmationModal);
