/**
 * Created by mateimisarca on 18.03.2021
 */

import { createSelector } from 'reselect';

const makeSelectUiStateContainer = state => state.uiState;

const makeSelectUiState = createSelector(makeSelectUiStateContainer, state => state);

export default makeSelectUiState;

