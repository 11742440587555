/**
 * Created by mateimisarca on 18/09/2020
 */

import { required, email } from 'utils/validations';

const loginValidation = (data) => {

    return {
        email: [required, email],
        password: [required],
    };
};

export default loginValidation;