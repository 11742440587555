/**
 * Created by mateimisarca on 24.05.2021
 */

import React, { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { bool, func, object, string } from 'prop-types';
import { filter, map, uniqueId } from 'lodash';
import { injectIntl } from 'react-intl';
import { Modal, InputGroup, FormControl, Form } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { withRouter } from 'react-router-dom';

import showNotification from 'containers/ToastContainer/actions';

import Button from 'components/Button';
import Loading from 'components/Loading';

import { makeSelectCurrentLocation } from 'containers/App/selectors';
import { makeSelectPageBreadcrumbs } from 'containers/PageContainer/selectors';

import ModalPortal from 'components/ModalPortal';

import getPageMessages from 'utils/pageMessages';
import getValidator from 'utils/validation';
import { renderInput } from 'utils/forms/input';
import { renderDropDown } from 'utils/forms/dropDown';
import { flattenObjectWithChildren } from 'utils/flattenObjectWithChildren';

import { OPTIONS_ENUM } from './constants';
import messages, { fieldMessages } from './messages';
import { feedbackModalClose, feedbackModalOpen } from './actions';
import makeSelectFeedbackModalOpen from './selectors';
import feedbackService from './service';
import validation from './validation';

import './FeedbackModal.scss';

const mapStateToProps = state => ({
  page: makeSelectCurrentLocation(state),
  open: makeSelectFeedbackModalOpen(state),
  breadcrumbs: makeSelectPageBreadcrumbs(state),
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => {
    setTimeout(() => dispatch(feedbackModalClose()), 250);
  },
  addToast: toast => dispatch(showNotification(toast)),
  openModal: () => dispatch(feedbackModalOpen()),
});

export default @compose(
  withRouter,
  reduxForm({
    form: 'feedbackForm',
    asyncValidate: getValidator(validation),
  }),
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)
class FeedbackModal extends PureComponent {
  static propTypes = {
    intl: object,
    page: string,
    open: bool,
    closeModal: func,
    openModal: func,
    addToast: func,
  };

  constructor(props) {
    super(props);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);

    this.state = {
      isLoading: false,
      errors: [],
      message: '',
      category: OPTIONS_ENUM[0],
    };
  }

  handleOnSubmit(values) {
    const { category, feedback } = values;
    const {
      page,
      pageSettings,
      pageParams,
      addToast,
      location: { pathname, search },
      intl: { formatMessage },
    } = this.props;
    const payload = {
      settings: pageSettings,
      params: pageParams,
    };

    this.setState({ isLoading: true });
    feedbackService
      .sendFeedback({
        feedback: {
          category: map(filter(flattenObjectWithChildren(category), o => o.checked), o => o.id)[0],
          feedback: feedback,
          data: { page, payload },
          url: `${ window.location.origin }${ pathname }${ search }`,
        },
      })
      .then(() => {
        addToast({
          title: formatMessage(messages.notifications.success.title),
          body: formatMessage(messages.notifications.success.body),
          type: 'inline',
          kind: 'success',
          timeout: 2500,
        });
        this.setState({ isLoading: false, message: '' });
        this.handleOnClose();
      })
      .catch(err => {
        addToast({
          title: formatMessage(messages.notifications.error.title),
          body: formatMessage(messages.notifications.error.body),
          kind: 'error',
          timeout: 3000,
          canClose: false,
        });
        const { errors } = this.state;
        this.setState({ errors: errors.concat(err), isLoading: false });
      });
  }

  handleOnClose() {
    const { closeModal } = this.props;
    closeModal();
  }

  renderModal() {
    const {
      intl: { formatMessage, messages },
      intl,
      open,
      handleSubmit,
    } = this.props;
    const { isLoading, errors, message } = this.state;
    const items = OPTIONS_ENUM.map(key => {
      const name = formatMessage(fieldMessages[key]);
      return { id: key, name };
    });
    return open ? (
      <ModalPortal>
        <Modal
          show={ !!open }
          onHide={ () => this.handleOnClose() }
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="Feedback"
          onKeyDown={ evt => {
            if (evt.which === 27) {
              this.handleOnClose();
            }
          } }
        >
          <Form onSubmit={ handleSubmit(this.handleOnSubmit) }>
            <Modal.Header closeButton>
              { getPageMessages(intl, 'feedback', 'title') }
            </Modal.Header>
            <Modal.Body>
              <p style={ { marginBottom: 25 } }>
                { getPageMessages(intl, 'feedback', 'message') }
              </p>
              {/*
            <FormGroup legendText={ formatMessage(messages.category) }>
              <Dropdown
                id="feedbackModal-dropDown"
                label={ `Select ${formatMessage(messages.category)}` }
                items={ items }
                initialSelectedItem={ items[0] }
                onChange={ ({ selectedItem }) => {
                  if (selectedItem) {
                    this.setState({ category: selectedItem.id });
                  }
                } }
              />
            </FormGroup>
            */ }
              <Field
                name="category"
                type="text"
                component={ renderDropDown }
                options={ items }
                disabled={ items.length === 0 }
                selectOnlyLeaves
                singleSelect
                placeholder={ getPageMessages(intl, 'feedback', 'category') }
              />
              <Field
                name="feedback"
                type="text"
                as="textarea"
                component={ renderInput }
                placeholder={ getPageMessages(intl, 'feedback', 'feedbackDescription') }
              />
            </Modal.Body>
            <Modal.Footer style={ { position: 'relative' } }>
              <Button variant="secondary" onClick={ this.handleOnClose }>
                { getPageMessages(intl, 'feedback', 'cancel') }
              </Button>
              <Button type="submit">
                { getPageMessages(intl, 'feedback', 'submit') }
              </Button>
              { isLoading && (
                <Loading
                  style={ { position: 'absolute', right: 10, top: 36 } }
                  active={ isLoading }
                  small
                  withOverlay={ false }
                />
              ) }
            </Modal.Footer>
          </Form>
        </Modal>
      </ModalPortal>
    ) : null;
  }

  render() {
    const { openModal, intl } = this.props;
    return (
      <div className="FeedbackModal">
        <div className="FeedbackModal-floatingBar">
          <Button
            small
            className="FeedbackModal-feedbackButton"
            onClick={ openModal }
          >
            { getPageMessages(intl, 'feedback', 'feedback') }
          </Button>
        </div>
        { this.renderModal() }
      </div>
    );
  }
}
