import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import './ConfirmationPage.scss';

export default class ConfirmationPage extends Component {
  static defaultProps = {
    icon: 'fa fa-check-circle',
    color: 'green',
  };

  render() {
    const { title, content, buttonText, icon, color } = this.props;

    return (
      <div className="registerSuccess error-page-sec pt-100 pb-100">
        <div className="registerSuccess-container">
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-8">
              <div className="registerSuccess-pageContent">
                <h1>{ title }</h1>
                <h2>{ content }</h2>
                <div className={ cx('registerSuccess-icon', icon) } style={ { color } } />
                <Link className="btn btn-warning" to="/">{ buttonText }</Link>
              </div>
            </div>
            <div className="col-md-2" />
          </div>
        </div>
      </div>
    );
  }
}
