/**
 * Created by mateimisarca on 21/09/2020
 */

import { handleActions } from 'redux-actions';

import { loginRequest, logoutRequest } from 'containers/AuthContainer/actions';
import { DEFAULT_JWT_TYPE, JWT_KEY, JWT_TYPE } from '../../constants';

export const initialState = {
  canLogin: false,
  loggedIn: false,
  error: null,
};

const authReducer = handleActions({
  [loginRequest.SUCCESS]: (state, { payload }) => {
    localStorage.setItem(JWT_KEY, payload.token);
    localStorage.setItem(JWT_TYPE, payload.tokenType || DEFAULT_JWT_TYPE);
    return Object.assign({}, state, { loggedIn: true, error: null });
  },
  [loginRequest.FAILURE]: (state, { payload: { error } }) => Object.assign({}, state, { loggedIn: false, error }),
  [logoutRequest]: (state, { payload: { error } }) => Object.assign({}, state, { loggedIn: false, error }),
}, initialState);

export default authReducer;
