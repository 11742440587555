/**
 * Created by mateimisarca on 22/09/2020
 */

import { defineMessages } from 'react-intl';

export default {
    notifications: {
        save: {
            error: defineMessages({
                title: {
                    id: 'app.components.Notifications.messages.pages.errors.save.title',
                    defaultMessage: `Page Data Update Error`,
                },
                body: {
                    id: 'app.components.Notifications.messages.pages.errors.save.body',
                    defaultMessage: `There was an error saving the { page } page data. Please try again or contact an administrator if the issue persists`,
                },
            }),
            success: defineMessages({
                title: {
                    id: 'app.components.Notifications.messages.pages.success.save.title',
                    defaultMessage: `Page Data Update Success`,
                },
                body: {
                    id: 'app.components.Notifications.messages.pages.success.save.body',
                    defaultMessage: `The { page } page data was successfully saved.`,
                },
            }),
        },
        data: {
            warning: defineMessages({
                title: {
                    id: 'app.components.Notifications.messages.pages.warnings.data.title',
                    defaultMessage: `Page Data Empty Response`,
                },
                body: {
                    id: 'app.components.Notifications.messages.pages.warnings.data.body',
                    defaultMessage: `There is no data available for the input you provided. Please try changing the filters`,
                },
            }),
            error: defineMessages({
                title: {
                    id: 'app.components.Notifications.messages.pages.errors.data.title',
                    defaultMessage: `Page Data Request Error`,
                },
                body: {
                    id: 'app.components.Notifications.messages.pages.errors.data.body',
                    defaultMessage: `There was an error requesting data for page { page }. Please try again or  contact an administrator if the issue persists`,
                },
                used: {
                    id: 'app.components.Notifications.messages.pages.errors.data.used',
                    defaultMessage: `The image is used { used } times. Please remove its usage before deleting it.`,
                },
            }),
        },
        settings: {
            save: {
                error: defineMessages({
                    title: {
                        id: 'app.components.Notifications.messages.pages.settings.errors.save.title',
                        defaultMessage: 'Page Settings Save Error',
                    },
                    body: {
                        id: 'app.components.Notifications.messages.pages.settings.errors.save.body',
                        defaultMessage: 'There was an error saving the settings for { page }. Please try again',
                    },
                }),
            },
            request: {
                error: defineMessages({
                    title: {
                        id: 'app.components.Notifications.messages.pages.settings.errors.request.title',
                        defaultMessage: 'Page Settings',
                    },
                    body: {
                        id: 'app.components.Notifications.messages.pages.settings.errors.request.body',
                        defaultMessage: 'There was an error requesting the settings for { page }. Please try again',
                    },
                }),
            },
            stages: {
                error: defineMessages({
                    title: {
                        id: 'app.components.Notifications.messages.pages.stages.errors.request.title',
                        defaultMessage: 'Page Stages',
                    },
                    body: {
                        id: 'app.components.Notifications.messages.pages.stages.errors.request.body',
                        defaultMessage: 'There was an error requesting the stages for { page }. Please try again',
                    },
                }),
            },
        },
        state: defineMessages({
            title: {
                id: 'app.components.Notifications.messages.pages.info.state.title',
                defaultMessage: 'State Applied',
            },
            body: {
                id: 'app.components.Notifications.messages.pages.info.state.body',
                defaultMessage: 'State from URL was successfully applied.',
            },
        }),
    },
};
