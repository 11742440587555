/**
 * Created by mateimisarca on 27.04.2021
 */

import { forEach, map, includes } from 'lodash';

export const composeDropdownData = (data, checkedArr = []) => {
  return map(data, item => {
    if (item.children) {
      const sonTree = composeDropdownData(item.children, checkedArr);
      if (sonTree) {
        let allTrue = true;
        let allFalse = true;

        forEach(item.children, item => {
          if (!item.checked) {
            allTrue = false;
          } else allFalse = false;
        });

        if (allTrue) {
          item.checked = true;
          item.partial = false;
        } else if (allFalse) {
          item.checked = false;
          item.partial = false;
        } else {
          item.checked = false;
          item.partial = true;
        }
      }
      return {
        ...item,
        label: item.name || item.title || item.status,
        children: sonTree,
      };
    }

    return {
      ...item,
      checked: includes(checkedArr, item.id) || item.checked,
      label: item.name || item.title || item.status,
    };
  });
};
