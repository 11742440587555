/**
 * Created by mateimisarca on 11.01.2023
 */

import logo_bloclit from 'assets/images/Logo-Bloclit-v2.png';
import logo_bloclitF from 'assets/images/Logo-Bloclit-v1-alb.png';
import logo_ideapapel from 'assets/images/logo-ideea-papel.png';
import logo_digitizer from 'assets/svg/digitizer.svg';

export const constants = {
  BLOCLIT: {
    colors: {
      topBar: 'rgb(255,153,0)',
      footer: '#808486',
      bottomBar: '#212b67',
    },
    logo: logo_bloclit,
    logoFooter: logo_bloclitF,
    phone: '0756 046 299',
    phoneLink: '0040756046299',
    mail: 'info@bloclit.ro',
    login: false,
    logoStyle: { height: '65px', maxWidth: 'none' }
  },
  IDEAPAPEL: {
    colors: {
      topBar: 'rgb(208,142,255)',
      footer: '#d16c34',
      bottomBar: '#212b67',
    },
    logo: logo_ideapapel,
    logoFooter: logo_ideapapel,
    logoStyle: { height: '65px', maxWidth: 'none' }
  },
  DIGITIZER: {
    colors: {
      footer: '#c6c6c6',
      footerText: '#393939'
    },
    phone: '0756 046 299',
    phoneLink: '0040756046299',
    mail: 'office@digitizer.ro',
    login: false,
    logo: logo_digitizer,
    logoFooter: logo_digitizer,
    logoStyle: { height: '65px', maxWidth: 'none' }
  },
};
