import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/scss/bootstrap-grid.scss';
import 'bootstrap/scss/bootstrap-reboot.scss';
// import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import './vendor/css/font-awesome.min.css';
import './vendor/css/magnific-popup.css';
import './vendor/css/owl.carousel.min.css';
import './vendor/css/animate.css';
import './vendor/css/meanmenu.min.css';
import './vendor/css/responsive.css';

import './vendor/css/main.css';

import './app.css';
// import '@fortawesome/fontawesome-free/css/fontawesome.css';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import deepmerge from 'deepmerge';
import { isPlainObject } from 'lodash';
import formActionSaga from 'redux-form-saga';
import ReactGA from 'react-ga4';

import App from './containers/App/App';
import LanguageProvider from 'containers/LanguageProvider';
import updateObject from 'utils/updateObject';
import { LOCAL_STORAGE_KEY } from 'constants/index';
import configureStore, { history } from 'store/configureStore';
import initialState from 'store/initialState';
// import ThemeProviderWrapper from 'Containers/ThemeProvider';
import { translationMessages } from './i18n';
import rootSaga from './sagas';

import * as serviceWorker from './serviceWorker';


ReactGA.initialize('G-3P2ELJ47JN');

// Create redux store with history, initial state and run the sagas
export const store = configureStore(
    deepmerge(
        initialState,
        updateObject(JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY))),
        {
            isMergeableObject: isPlainObject,
        }, // initial state
    ),
);
store.runSaga(rootSaga);
store.runSaga(formActionSaga);
const MOUNT_NODE = document.getElementById('root');

const render = (AppRoot, messages) => {
    ReactDOM.render(
        <Provider store={ store }>
            <ConnectedRouter history={ history }>
                <LanguageProvider messages={ messages }>
                    {/*<ThemeProviderWrapper>*/ }
                    <AppRoot />
                    {/*</ThemeProviderWrapper>*/ }
                </LanguageProvider>
            </ConnectedRouter>
        </Provider>,
        MOUNT_NODE,
    );
};

if (module.hot) {
    // Hot reloadable React components and translation json files
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept('./containers/App/App.jsx', () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render(require('./containers/App/App.jsx').default, translationMessages); // eslint-disable-line global-require
    });
    // Hot reloadable translation json files
    module.hot.accept('./i18n', () => {
        render(App, require('./i18n').translationMessages); // eslint-disable-line global-require
    });
}

// Chunked polyfill for browsers without Intl support
/* eslint-disable */
if (!window.Intl) {
    (new Promise(resolve => resolve(require('intl'))))
        .then(() => Promise.all([
            require('intl/locale-data/jsonp/en.js'),
            require('intl/locale-data/jsonp/ro.js'),
        ]))
        .then(() => render(App, translationMessages))
        .catch(err => throw err);
} else {
    render(App, translationMessages);
}
/* eslint-enable */


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
