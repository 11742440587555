/**
 * Created by mateimisarca on 21/09/2020
 */

import { createFormAction } from 'redux-form-saga';

import { USER_REQUEST, PROFILE_UPDATE_REQUEST } from './constants';

export const getUserRequest = createFormAction(USER_REQUEST);
export const profileUpdateRequest = createFormAction(PROFILE_UPDATE_REQUEST);
