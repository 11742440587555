/**
 * Created by mateimisarca on 18/09/2020
 */

import { saveAs } from 'file-saver/dist/FileSaver';
import { isPlainObject, isObject, includes } from 'lodash';
import 'whatwg-fetch';

import updateObject from 'utils/updateObject';
import { USER_LOGOUT_REQUEST } from 'containers/AuthContainer/constants';
import { errorMessages } from 'containers/AuthContainer/messages';
import { JWT_KEY, JWT_TYPE } from 'constants/index';

const REQUEST_STACK = [];

/**
 * Check and perform based on response status
 * @param response {HttpResponse}
 * @returns {HttpResponse|ErrorEvent}
 */
const checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    if (response.status === 401) {
        if (includes(response.url, '/auth/signin')) {
            throw response;
        } else {
            const event = new CustomEvent(USER_LOGOUT_REQUEST, {
                detail: {
                    error: new Error(errorMessages.session.id),
                },
            });
            document.dispatchEvent(event);
            Object.assign(response, { message: errorMessages.session.id });
            throw response;
        }
    } else {
        throw response;
    }
};

const parseResponse = async response => {
    const contentType = response?.headers?.get('Content-Type');
    const contentDisposition = response?.headers?.get('content-disposition');
    if (contentType && contentType.match('json') && !contentDisposition) {
        return response.json();
    }
    if (
        contentType &&
        contentDisposition &&
        /^attachment/.test(contentDisposition)
    ) {
        // Download attachments
        const fileName = contentDisposition
            .match(/filename[^;=\n]*=(UTF-8(['"]*))?(.*)/)[3]
            .replace(/"/g, '');
        const data = await response.arrayBuffer();
        saveAs(new Blob([data], { type: contentType }), fileName);
        return data;
    }
    if (response) {
        return response?.text();
    }
};

const handleErrors = response => {
    if (response.code === 20) {
        // Request cancellation, don't throw;
        return;
    }
    const contentType = response?.headers?.get('Content-Type');
    if (contentType) {
        /*
            if (contentType.match('json')) {
              throw response.json();
            }
        */
        return response.text().then(res => throw res); // eslint-disable-line
    } else if (response.code !== 401 || includes(response.url, '/auth/signin')) {
        throw response;
    }
};

export default (path = null, options) => {
    const URI =
        typeof path !== 'string' && path !== null ? path?.url || path.path : path;
    if (isPlainObject(path) && !options) {
        options = path; // eslint-disable-line
    }
    if (
        !URI ||
        path === '' ||
        (typeof URI !== 'string' && !(URI instanceof URL))
    ) {
        throw new Error(`Invalid request to unknown path: ${ path }`);
    }
    let url;
    if (URI instanceof URL) {
        url = URI;
    } else {
        url = new URL(
            `${ window.location?.origin || document.location?.origin }${ URI }`,
        );
    }

    Reflect.ownKeys({ ...options?.params }).forEach(key => {
        let param = options.params[key];
        if (isObject(param)) {
            param = JSON.stringify(param);
        }
        url.searchParams.append(key, param);
    });

    const request = {
        id: URI,
        controller: new AbortController(),
    };

    if (!options?.method || options?.method === 'GET') {
        for (const req of REQUEST_STACK) { // eslint-disable-line
            if (request.id === req.id) {
                req.controller.abort();
                console.log(
                    `%c__REQUEST__ Cancelled for URI: %c${ req.id }`,
                    'color: blue',
                    'font-weight: bold',
                );
            }
        }
        REQUEST_STACK.push(request);
    }

    return fetch(
        url,
        updateObject(
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    Authorization: `${ localStorage.getItem(
                        JWT_TYPE,
                    ) } ${ localStorage.getItem(JWT_KEY) }`,
                },
                credentials: 'same-origin',
                referrer: 'no-referrer',
                signal: request.controller.signal,
            },
            options,
        ),
    )
        .then(checkStatus)
        .then(parseResponse)
        .catch(handleErrors)
        .finally(() => {
            const requestIdx = REQUEST_STACK.findIndex(v => v.id === request.id);
            if (requestIdx !== -1) {
                REQUEST_STACK.splice(requestIdx, 1);
            }
        });
};

