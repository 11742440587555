/**
 * Created by mateimisarca on 28.04.2021
 */

import { filter, includes } from 'lodash';

export const flattenObjectWithChildren = (arr, returnLevels, lvl = 0) => {
  const result = arr && Array.isArray(arr) ? arr.reduce((result, item) => [
    ...result,
    { ...item, lvl, children: null },
    ...flattenObjectWithChildren(item.children, null, lvl + 1),
  ], []) : arr ? [arr] : [];

  if (returnLevels) {
    return filter(result, o => includes(returnLevels, o.lvl));
  }

  return result;
};
