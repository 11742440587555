/**
 * Created by mateimisarca on 26.04.2021
 */

import React, { Component } from 'react';
import cx from 'classnames';
import { bool } from 'prop-types';

import './RadioButton.scss';

export default class RadioButton extends Component {
  static propTypes = {
    disabled: bool,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    const { checked, onClick, labelText, partial, disabled } = this.props;

    return (
      <div className={ cx('Radio', {
        'Radio--disabled': disabled,
      }) }>
        <label className="Radio-label">
          <div className="Radio-container">
            <input
              className="Radio-input"
              type="Radio"
              checked={ checked || partial }
              onChange={ onClick }
              disabled={ disabled }
            />
            <div className={ cx('Radio-styled', {
              'Radio-styled--checked': checked || partial,
              'Radio-styled--disabled': disabled,
              'Radio-styled--partial': partial,
            }) }>
              <div className="Radio-styledInner">
                <svg className="Radio-icon" viewBox="0 0 24 24">
                  {/*{ checked ? <polyline points="20 6 9 17 4 12" /> :*/}
                  {/*  <polyline points="18 12 6 12" /> }*/}
                </svg>
              </div>
            </div>
          </div>
          <span>{ labelText }</span>
        </label>
      </div>
    );
  }
}
