/**
 * Created by mateimisarca on 18/09/2020
 */

export const APP_STATE_CLEAR = 'app/containers/App/APP_STATE_CLEAR';
export const APP_DATA_REQUEST = 'app/PageContainer/APP_DATA_REQUEST';
export const CHANGE_WEBSITE = 'app/PageContainer/CHANGE_WEBSITE';

export const API_PATH_DATA = '/api/getGeneralSettings';
export const API_PAGE_VIEW = '/api/pageView';
export const API_MESSAGE = '/api/message';

