/**
 * Created by mateimisarca on 17/09/2020
 */

export const LOCAL_STORAGE_KEY = 'DIGITIZER_ADMIN';
export const JWT_KEY = 'jwtToken';
export const JWT_TYPE = 'jwtType';
export const DEFAULT_JWT_TYPE = 'Bearer';

// UI
export const UI_IS_NAVIGATION_OPEN = 'app/ui/UI_IS_NAVIGATION_OPEN'
export const UI_APP_WIDTH = 'app/ui/UI_WIDTH'

// Globals
export const DEFAULT_CURRENCY = 'RON';
export const currencyMap = [
  ['$', 'USD'],
  ['€', 'EUR'],
  ['£', 'GBP'],
  ['¥', 'YUAN'],
];
