/**
 * Created by mateimisarca on 02.12.2022
 */

import { createFormAction } from 'redux-form-saga';
import {
  CATEGORIES_REQUEST,
  PRODUCT_CREATE,
  PRODUCT_PHOTO_ADD,
  PRODUCT_PHOTO_DELETE,
  PRODUCT_REQUEST,
  PRODUCT_UPDATE,
  PRODUCTS_REQUEST,
} from './constants';

export const productsRequest = createFormAction(PRODUCTS_REQUEST);
export const productRequest = createFormAction(PRODUCT_REQUEST);
export const productCreate = createFormAction(PRODUCT_CREATE);
export const productUpdate = createFormAction(PRODUCT_UPDATE);
export const productPhotoAdd = createFormAction(PRODUCT_PHOTO_ADD);
export const productPhotoDelete = createFormAction(PRODUCT_PHOTO_DELETE);
export const categoriesRequest = createFormAction(CATEGORIES_REQUEST);
