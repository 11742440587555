/**
 * Created by mateimisarca on 27/09/2020
 */

import { kebabCase, join, split, reduce } from 'lodash';

function pageMessages(locationArray, messages) {
  return reduce(['pages', ...locationArray], (acc, key, idx) => {
    if (acc && key in acc && !('id' in acc[key])) {
      return acc[key];
    }

    return acc;
  }, (messages));
}

const getPageMessages = (intl, page, key) => {
  if (page === undefined || page === null) return null;
  const keyObj = typeof key === 'object' ? key : {
    id: kebabCase(key),
    defaultMessage: key,
  };

  return intl.formatMessage({
    ...keyObj,
    ...intl.messages.generic[key],
    ...pageMessages(split(page[0] === '/' ? page.substring(1) : page, '/'), intl.messages)?.[key],
  });

};

export default getPageMessages;
