/**
 * Created by mateimisarca on 08.07.2021
 */

import React from 'react';

import { call, put, takeLatest } from 'redux-saga/effects';

import showNotification from 'containers/ToastContainer/actions';

import FormattedMessage from 'components/FormattedMessage';

import fetch from 'utils/fetch';
import logger from 'utils/logger';

import messages from './messages';
import { mainPageRequest, pageDataRequest } from './actions';
import { API_PATH, MAIN_PAGE_API_PATH } from './constants';

function* getPageData({ payload: { body, PATH = API_PATH, ...rest } }) {
  try {
    const data = yield call(fetch, PATH, {
      method: 'POST',
      body: JSON.stringify(body),
      ...rest,
    });
    yield put(pageDataRequest.success({ data: data?.result, page: body.page }));
  } catch (error) {
    const page = body.page;
    yield put(
      showNotification({
        title: (
          <FormattedMessage { ...messages.notifications.data.error.title } />
        ),
        body: (
          <FormattedMessage
            { ...messages.notifications.data.error.body }
            values={ { page: <strong>{ page }</strong> } }
          />
        ),
        kind: 'error',
      }),
    );
    yield put(pageDataRequest.failure({ error }));
    if (error?.payload?.action) {
      yield put(error.payload.action);
    }
    logger.error(error);
  }
}

function* getMainPage({ payload: { body, PATH = MAIN_PAGE_API_PATH, ...rest } }) {
  try {
    const data = yield call(fetch, PATH, {
      ...rest,
    });
    yield put(mainPageRequest.success({ data: data?.result }));
  } catch (error) {
    const page = 'MAIN';
    yield put(
      showNotification({
        title: (
          <FormattedMessage { ...messages.notifications.data.error.title } />
        ),
        body: (
          <FormattedMessage
            { ...messages.notifications.data.error.body }
            values={ { page: <strong>{ page }</strong> } }
          />
        ),
        kind: 'error',
      }),
    );
    yield put(mainPageRequest.failure({ error }));
    if (error?.payload?.action) {
      yield put(error.payload.action);
    }
    logger.error(error);
  }
}

export const pageSagas = [
  takeLatest(pageDataRequest.REQUEST, getPageData),
  takeLatest(mainPageRequest.REQUEST, getMainPage),
];
