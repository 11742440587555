/**
 * Created by mateimisarca on 14/04/2020
 */

import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import './P404.scss';
import { injectIntl } from 'react-intl';

export default @injectIntl
class P404 extends PureComponent {
    render() {
        const { formatMessage, messages } = this.props.intl;
        return (
            <section className="p-0 p404">
                <div className="container">
                    <Row>
                        <Col sm={ 12 }>
                            <div className="p404-wrapper">
                                <h1>404</h1>
                                <h2>{ formatMessage(messages.p404.title) }</h2>
                                <Link to="/">
                                    { formatMessage(messages.p404.backHome) }
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        );
    }
}
