/**
 * Created by mateimisarca on 12.01.2021
 */

export const PAGE_DATA_REQUEST = 'app/PageContainer/PAGE_DATA_REQUEST';
export const PAGE_UPDATE = 'app/PageContainer/PAGE_UPDATE';
export const PAGE_CLEAR = 'app/PageContainer/PAGE_CLEAR';
export const PAGES_CLEAR = 'app/PageContainer/PAGES_CLEAR';
export const MESSAGE_ADD = 'app/PageContainer/MESSAGE_ADD';
export const MAIN_PAGE_REQUEST = 'app/PageContainer/GET_MAIN_PAGE';

export const BREADCRUMB_SET_ACTIVE = 'app/PageContainer/BREADCRUMB_SET_ACTIVE';
export const BREADCRUMB_REMOVE_ACTIVE = 'app/PageContainer/BREADCRUMB_REMOVE_ACTIVE';
export const BREADCRUMB_CLEAR = 'app/PageContainer/BREADCRUMB_CLEAR';

export const API_PATH = '/api/he/getPageData'
export const MAIN_PAGE_API_PATH = '/api/home-page'