/**
 * Created by mateimisarca on 18/09/2020
 */

export const USER_REGISTER_REQUEST = 'app/AuthPage/USER_REGISTER';
export const USER_LOGIN_REQUEST = 'app/AuthPage/USER_LOGIN';
export const USER_LOGOUT_REQUEST = 'app/AuthPage/USER_LOGOUT';
export const ACCOUNT_VERIFY = 'app/AuthPage/ACCOUNT_VERIFY';

export const API_USER_CONSENT = '/api/user/acceptConsent';
export const API_USER_PAGE_SETTINGS = '/api/user/pageSettings';
export const API_AUTH = '/api/auth/signin';
export const API_REGISTER = '/api/auth/register';
export const API_VERIFY_ACCOUNT = '/api/auth/verify-account';
