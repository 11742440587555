/**
 * Created by mateimisarca on 18/09/2020
 */

import { createSelector } from 'reselect';

const appStateDomain = state => state.appState;
const pageStateDomain = state => state.page;
const locationStateDomain = state => state.router;

export const makeSelectCurrentLocation = createSelector(
  locationStateDomain,
  routeState => routeState.location.pathname.replace(/^(\/)|(\/)$/g, ''),
);

export const makeSelectCurrentPageTitle = createSelector(
  locationStateDomain,
  pageStateDomain,
  (routeState, state) => state[routeState.location.pathname.replace(/^(\/)|(\/)$/g, '')]?.title,
);

export const makeSelectCurrentPageKey = createSelector(
  locationStateDomain,
  pageStateDomain,
  (routeState, state) => state[routeState.location.pathname.replace(/^(\/)|(\/)$/g, '')]?.key,
);

export const makeSelectCurrentPageSettings = createSelector(
  locationStateDomain,
  pageStateDomain,
  (routeState, state) => state[routeState.location.pathname.replace(/^(\/)|(\/)$/g, '')]?.settings,
);

export const makeSelectCurrentTopArea = createSelector(
  locationStateDomain,
  pageStateDomain,
  (routeState, state) => state[routeState.location.pathname.replace(/^(\/)|(\/)$/g, '')]?.topArea,
);

export const makeSelectLocation = createSelector(
  locationStateDomain,
  routeState => routeState.location,
);

export const makeSelectLanguage = createSelector(
  appStateDomain,
  state => state.userSettings.language,
);

export const makeSelectCurrency = createSelector(
  appStateDomain,
  state => state.userSettings.currency,
);

export const makeSelectIcons = createSelector(
  appStateDomain,
  state => state.icons,
);

export const makeSelectActivePage = createSelector(
  appStateDomain,
  state => state.pathname,
);

export const makeSelectActiveFilters = createSelector(
  appStateDomain,
  state => state.activeFilters,
);
