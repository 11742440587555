/**
 * Created by mateimisarca on 29/11/2019
 */

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { accountVerify } from 'containers/AuthContainer/actions';
import { push } from 'connected-react-router';

const mapDispatchToProps = dispatch => ({
  verifyToken: token => accountVerify({ token }, dispatch),
  goTo: page => dispatch(push(page)),
});

export default @compose(
  withRouter,
  connect(null, mapDispatchToProps),
)
class VerifyAccount extends PureComponent {
  constructor(props) {
    super(props);

    const {
      location: {
        search,
      },
      goTo,
      verifyToken,
    } = props;
    const query = qs.parse(search, { ignoreQueryPrefix: true });

    verifyToken(query.token).then((res) => {
      if (!res.error) {
        goTo('/auth/verify-account/success');
      }
    });
  }

  render() {
    return (
      <div>

      </div>
    );
  }
}
