/**
 * Created by mateimisarca on 21/09/2020
 */

import React, { Component, Fragment } from 'react';
import { Switch, withRouter } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import cx from 'classnames';
import { includes } from 'lodash';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import Footer from 'components/Footer';

import LoginPage from 'pages/Auth/Login';
import RegisterPage from 'pages/Auth/Register';
import VerifyAccount from 'pages/Auth/VerifyAccount';
import ConfirmationPage from 'pages/ConfirmationPage';

import getPageMessages from 'utils/pageMessages';

export default @compose(
  injectIntl,
  withRouter,
)
class AuthContainer extends Component {
  render() {
    const { loggedIn, location: { pathname }, intl } = this.props;

    return (
      <Fragment>
        <div className={ cx('Container-wrapper', {
          'Container-wrapper--minHeight': includes(pathname, 'auth'),
        }) }>
          <Switch>
            <Route path="/auth/verify-account/success" exact render={ () => (
              <ConfirmationPage
                title={ getPageMessages(intl, 'verifyAccountSuccess', 'congratulations') }
                content={ getPageMessages(intl, 'verifyAccountSuccess', 'content') }
                buttonText={ getPageMessages(intl, 'verifyAccountSuccess', 'backToTheSite') }
              />
            ) } />
            <Route path="/auth/register/success" exact render={ () => (
              <ConfirmationPage
                title={ getPageMessages(intl, 'registerSuccess', 'congratulations') }
                content={ getPageMessages(intl, 'registerSuccess', 'content') }
                buttonText={ getPageMessages(intl, 'registerSuccess', 'backToTheSite') }
                icon="fa-solid fa-circle-exclamation"
                color="#ffc107"
              />
            ) } />
            <Route path="/auth/verify-account" component={ VerifyAccount } />
            <Route path="/auth/login" render={ () => <LoginPage /> } />
            <Route path="/auth/register" render={ () => <RegisterPage /> } />

            <Route
              path="/auth"
              exact
              render={ () => {
                if (loggedIn) {
                  return <Redirect to="/" />;
                }

                return <Redirect to="/auth/login" />;
              } }
            />
          </Switch>
        </div>
        {/*<Footer />*/}
      </Fragment>
    );
  }
}
