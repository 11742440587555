/**
 * Created by mateimisarca on 24.05.2021
 */

import { handleActions } from 'redux-actions';
import { feedbackModalClose, feedbackModalOpen } from './actions';

export const initialState = {
  open: false,
};

const feedbackModalReducer = handleActions(
  {
    [feedbackModalOpen]: () => ({ open: true }),
    [feedbackModalClose]: () => ({ open: false }),
  },
  initialState,
);

export default feedbackModalReducer;

