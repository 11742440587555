/**
 * Created by mateimisarca on 17/09/2020
 */

import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware, LOCATION_CHANGE } from 'connected-react-router';
import { createMiddleware, createLoader } from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import debounce from 'redux-storage-decorator-debounce';
import filter from 'redux-storage-decorator-filter';
import update from 'immutability-helper';
import { fromJS } from 'immutable';
import { createBrowserHistory } from 'history';
import createSagaMiddleware, { END } from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createRootReducer from 'reducers/index';
import { LOCAL_STORAGE_KEY } from 'constants/index';
import updateObject from 'utils/updateObject';
import logger from 'utils/logger';
import routeChange from '../middleware/routeChange';

const sagaMiddleware = createSagaMiddleware({
    sagaMonitor: {
        actionDispatched(action) {
            if (process.env.NODE_ENV !== 'production') logger.log(action);
        },
    },
    onError: error => {
        logger.error(`__SAGA__ ${error}`);
    },
});
const storageEngine = (() => {
    const engine = debounce(
        filter(createEngine(LOCAL_STORAGE_KEY), [
            // whitelist
            ['auth', 'loggedIn'],
            ['user', 'email'],
            ['uiState', 'updateUI'],
            ['uiState', 'resetUi'],
            ['appState', 'userSettings'],
            ['appState', 'activeFilters'],
            ['appState', 'stages'],
            ['appState', 'page', 'path'],
            ['appState', 'page', 'name'],
            ['appState', 'page', 'settings'],
            ['appState', 'page', 'params'],
        ]),
        1000, // 1s debounce.
    );

    return {
        ...engine,

        load() {
            return engine.load().then(result => {
                if (!('appState' in result)) {
                    return result;
                }

                return update(result, {
                    appState: {
                        activeFilters: {
                            $set: result.appState.activeFilters,
                        },
                    },
                });
            });
        },
    };
})();
const localStorageMiddleware = createMiddleware(storageEngine, [
    LOCATION_CHANGE,
]);
export const history = createBrowserHistory();

/**
 * This will create the enhanced Redux Store
 * @param initialState {Map|Object}
 * @returns {Object} - Store<Reducer, State, Enhancer>
 */
export default function configureStore(initialState) {
    /** Redux middleware collection
     * 1. sagaMiddleware: Makes redux-sagas work
     * 2. storageMiddleware: Syncs store with local storage
     * 3. routerMiddleware: Syncs state with window.location
     */
    const middlewares = [
        sagaMiddleware,
        localStorageMiddleware,
        routeChange(),
        routerMiddleware(history),
    ];

    const enhancers = [applyMiddleware(...middlewares)];

    const composeEnhancers = composeWithDevTools({
        shouldHotReload: false,
    });

    /**
     * App store contains:
     * a. App reducers (routerReducer, languageReducer, authReducer etc)
     * b. Initial application state
     * c. Other store enhancers: (ReduxDevtoolsExtension, Redux-Saga, routerMiddleware)
     */
    const store = createStore(
        createRootReducer(history),
        initialState,
        composeEnhancers(...enhancers),
    );

    // Load the Store with data from localStorage
    createLoader(storageEngine)(store);

    // Store Extensions. we need to call runSaga with our Sagas list in order to register them
    store.runSaga = sagaMiddleware.run;
    store.injectedReducers = {}; // Reducer registry
    store.injectedSagas = {}; // Saga registry
    store.closeSaga = () => store.dispatch(END); // Dispatches @@redux-saga/CHANNEL_END event

    // Make reducers hot reloadable, see http://mxs.is/googmo
    /* istanbul ignore next */
    if (module.hot) {
        module.hot.accept('../reducers/index.js', () => {
            store.replaceReducer(createRootReducer(history, store.injectedReducers));
        });
    }

    return store;
}

