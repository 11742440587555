/**
 * Created by mateimisarca on 24.05.2021
 */

import { required } from 'utils/validations';

const validation = (data) => ({
  category: [required],
  feedback: [required],
});

export default validation;

