/**
 * Created by mateimisarca on 12.01.2021
 */

import { createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';

import {
  PAGE_DATA_REQUEST,
  PAGE_UPDATE,
  PAGE_CLEAR,
  PAGES_CLEAR,
  BREADCRUMB_SET_ACTIVE,
  BREADCRUMB_REMOVE_ACTIVE,
  BREADCRUMB_CLEAR,
  MESSAGE_ADD,
  MAIN_PAGE_REQUEST,
} from './constants';

export const pageDataRequest = createFormAction(PAGE_DATA_REQUEST);

export const pagePropsUpdate = createAction(PAGE_UPDATE, (page, payload) => ({
  page: page.replace(/^(\/)|(\/)$/g, ''),
  payload,
}));

export const pageClear = createAction(PAGE_CLEAR, page => ({
  page: page.replace(/^(\/)|(\/)$/g, ''),
}));

export const breadcrumbSetActive = createAction(
  BREADCRUMB_SET_ACTIVE,
  (page, breadcrumb) => ({ page, breadcrumb }),
);
export const breadcrumbRemoveActive = createAction(BREADCRUMB_REMOVE_ACTIVE, (page, idx) => ({ page, idx }));
export const breadcrumbClear = createAction(BREADCRUMB_CLEAR, page => ({ page }));

export const pagesClear = createAction(PAGES_CLEAR);

export const addMessage = createFormAction(MESSAGE_ADD)
export const mainPageRequest = createFormAction(MAIN_PAGE_REQUEST)