/**
 * Created by mateimisarca on 17/09/2020
 */

import React, { Component } from 'react';

export default class Loading extends Component {
    render() {
        return (
            <div>
                Loading
            </div>
        )
    }
}
