/**
 * Created by mateimisarca on 21/09/2020
 */

import React from 'react';

import { call, put, takeEvery } from 'redux-saga/effects';

import showNotification from 'containers/ToastContainer/actions';
import messages from 'containers/PageContainer/messages';

import FormattedMessage from 'components/FormattedMessage/FormattedMessage';

import fetch from 'utils/fetch';
import logger from 'utils/logger';

import { API_USER_PROFILE } from './constants';
import { getUserRequest, profileUpdateRequest } from './actions';

function* fetchUser() {
    try {
        const user = yield call(fetch, API_USER_PROFILE);
        yield put(getUserRequest.success(user));
    } catch (error) {
        // ToDo: add makeSelectPageName implementation
        // const name = yield select(makeSelectPageName);
        const name = 'page name';

        yield put(
            showNotification({
                title: (
                    <FormattedMessage { ...messages.notifications.data.error.title } />
                ),
                body: (
                    <FormattedMessage
                        { ...messages.notifications.data.error.body }
                        values={ { page: <strong>{ name }</strong> } }
                    />
                ),
                kind: 'error',
            }),
        );
        yield put(getUserRequest.failure({ error }));
        if (error?.payload?.action) {
            yield put(error.payload.action);
        }
        logger.error(error);
    }
}

function* updateProfile({ payload }) {
    const name = 'profile';
    try {
        const profile = yield call(fetch, API_USER_PROFILE, {
            method: 'POST',
            body: JSON.stringify(payload),
        });
        yield put(
            showNotification({
                title: (
                    <FormattedMessage { ...messages.notifications.save.success.title } />
                ),
                body: (
                    <FormattedMessage
                        { ...messages.notifications.save.success.body }
                        values={ { page: <strong>{ name }</strong> } }
                    />
                ),
                kind: 'success',
            }),
        );
        yield put(profileUpdateRequest.success({ ...profile }));
    } catch (error) {
        // ToDo: add makeSelectPageName implementation
        // const name = yield select(makeSelectPageName);

        yield put(
            showNotification({
                title: (
                    <FormattedMessage { ...messages.notifications.save.error.title } />
                ),
                body: (
                    <FormattedMessage
                        { ...messages.notifications.save.error.body }
                        values={ { page: <strong>{ name }</strong> } }
                    />
                ),
                kind: 'error',
            }),
        );
        yield put(profileUpdateRequest.failure({ error }));
        if (error?.payload?.action) {
            yield put(error.payload.action);
        }
        logger.error(error);
    }
}

export default function* () {
    yield takeEvery(getUserRequest.REQUEST, fetchUser);
    yield takeEvery(profileUpdateRequest.REQUEST, updateProfile);
}

export const userSagas = [
    takeEvery(getUserRequest.REQUEST, fetchUser),
    takeEvery(profileUpdateRequest.REQUEST, updateProfile),
];
