/**
 * Created by mateimisarca on 21/09/2020
 */

import React, { Component } from 'react';
import { Alert, Toast } from 'react-bootstrap';
import { bool, oneOf, string } from 'prop-types';
import { isEqual, uniqueId } from 'lodash';

import './notification.scss';

export default class Notification extends Component {
    static propTypes = {
        title: string,
        subtitle: string,
        hideCloseButton: bool,
        kind: oneOf(['primary',
            'secondary',
            'success',
            'danger',
            'warning',
            'info',
            'light',
            'dark',
            'error',
        ]),
    };

    static defaultProps = {
        hideCloseButton: false,
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !isEqual(this.props, nextProps)
    }

    render() {
        const {
            title,
            timeout,
            onClose,
            subtitle,
            notification,
            hideCloseButton,
            clearNotification,
            currentNotifications,
            kind,
        } = this.props;

        return (
            <Toast
                className="notification"
                key={ uniqueId('notification') }
                delay={ timeout }
                autohide={ !!timeout }
                onClose={ () => {
                    if (currentNotifications.indexOf(notification) !== -1) {
                        if (onClose && typeof onClose === 'function') onClose();
                        clearNotification(notification);
                    }
                } }
            >
                <Toast.Header closeButton={ !hideCloseButton }>
                    <strong className="mr-auto">{ title }</strong>
                </Toast.Header>
                <Toast.Body>
                    <Alert className="notification-alert" variant={ kind === 'error' ? 'danger' : kind }>
                        { subtitle }
                    </Alert>
                </Toast.Body>
            </Toast>
        );
    }
}
